import * as React from 'react';
import GoToTop from './../../components/Layout/GoToTop';
import SocialLinks from './../../components/Layout/SocialLinks';

const Tos = () => {
  return (
    <div>
      <SocialLinks />
      <GoToTop />
      <div className='tos-container m-5'>
        <div className='container px-4 px-lg-5 pt-5 d-flex flex-column h-100 align-items-center justify-content-center'>
          <div className='flex-fill w-100 pt-3 pb-5'>
            <div className='section-item flex-fill w-100 expanded'>
              <div className='section-page-head flex-fill w-100'>
                <h1>Age Of Zalmoxis Terms of Service Agreement</h1>
              </div>
              <div className='flex-fill w-100 pt-5'>
                <div>
                  Last Updated on 14 March 2022
                  <br />
                  <br />
                  AGE OF ZALMOXIS (the “Game”) is LICENSED FOR USE BY Wenmoon
                  Studios Ltd.. (“Wenmoon”) a limited liability company
                  incorporated under the laws of IRELAND with principal offices
                  at Coliemore House, Coliemore Road, Dalkey, Co. Dublin, A96
                  A8D5, Ireland. PLEASE READ This TERMS OF SERVICE AGREEMENT for
                  the Game (This “Terms of Service”) CAREFULLY.
                  <br />
                  <br />
                  BY CLICKING THE “ACCEPT“ OR “I AGREE” BUTTON TO ENTER THE GAME
                  OR BY PARTICIPATING IN or otherwise operating the game, or BY
                  USING THE GAME WEBSITE (
                  <a
                    href='https://www.ageofzalmoxis.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://www.ageofzalmoxis.com
                  </a>
                  ) or (
                  <a
                    href='https://marketplace.ageofzalmoxis.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://marketplace.ageofzalmoxis.com/
                  </a>
                  ) (THE “SITE”), THE USER (“YOU”) AGREES THAT THIS TERMS OF
                  SERVICE IS AN ENFORCEABLE, LEGALLY BINDING DOCUMENT. IF YOU DO
                  NOT AGREE TO ALL OF THE provisions of this Terms of SERVICE.
                  CLICK ON THE BUTTON THAT INDICATES THAT YOU DO NOT AGREE TO
                  ACCEPT THE TERMS OF THIS TERMS OF SERVICE AND DO NOT
                  PARTICIPATE IN THE GAME, GAME SERVICES OR USE THE SITE.
                  <br />
                  <br />
                  ONCE YOU AGREE TO THIS TERMS OF SERVICE FOR THE GAME”) AND
                  SERVICE FOR THE GAME COMMENCES, YOU WILL NO LONGER BE ELIGIBLE
                  FOR A REFUND FOR ANY AMOUNTS OR OTHER CONSIDERATION PAID BY
                  YOU FOR THE USE (OR FUTURE USE) OF THE GAME CLIENT OR THE
                  SERVICE.
                  <br />
                  <br />
                  The Game includes two components: (a) the software program
                  along with any accompanying materials or documentation
                  (collectively, the “Game Client”), and (b) the online game
                  play service (“Service”). This Terms of Service sets forth the
                  terms and conditions under which you are provided access to
                  the Game Client, support, the Site, billing, and the Service
                  by Wenmoon for “Age Of Zalmoxis”.
                  <br />
                  <br />
                </div>
                <h2>1. Grant of a Limited License to Use the Service.</h2>{' '}
                <div>
                  <br />
                  Subject to your agreement to and continuing compliance with
                  this Terms of Service, you may use the Service solely for your
                  own non-commercial entertainment purposes by accessing it with
                  a web browser or an authorized, unmodified Game Client. You
                  may not use the Service for any other purpose or using any
                  other method.
                  <br />
                  <br />
                </div>
                <h2>2. Additional License Limitations.</h2>
                <div>
                  <br />
                  The license granted to you in Section 1 is subject to the
                  limitations set forth in this Terms of Service (collectively,
                  the &quot;License Limitations&quot;). Any use of the Service
                  or the Game Client in violation of the License Limitations
                  will be regarded as an infringement of Wenmoon’s copyrights in
                  and to the Service and/or Game Client. You agree that you will
                  not, under any circumstances:
                  <ol>
                    <li>
                      Use cheats, automation software (bots), hacks, mods or any
                      other third-party software designed to modify, enhance, or
                      otherwise alter the Service, the Game Client or the Game
                      experience without the express written consent of Wenmoon.
                    </li>

                    <li>
                      Intercept, emulate or redirect the communication protocols
                      used by Game in any way, for any purpose, including
                      without limitation unauthorized play over the Internet,
                      network play, or as part of content aggregation networks
                      or by developing, hosting or offering other services
                      (e.g., matchmaking services).
                    </li>

                    <li>
                      Facilitate, create or maintain any unauthorized connection
                      to the Game, including without limitation (a) any
                      connection to any unauthorized server that emulates, or
                      attempts to emulate, the Service or the Game Client; and
                      (b) any connection using programs or tools not expressly
                      approved by Wenmoon.
                    </li>

                    <li>
                      Use any unauthorized third-party software that intercepts,
                      &quot;mines&quot;, or otherwise collects information from
                      or through the Game, including without limitation any
                      software that reads areas of RAM used by the Game Client
                      or the Service to store information about a character or a
                      Game environment. Modify or cause to be modified any files
                      that are a part of the Game Client or the Service in any
                      way not expressly authorized by Wenmoon.
                    </li>

                    <li>
                      Disrupt or assist in the disruption of (i) any computer
                      used to support the Game Client, the Service or any Game
                      environment (each a &quot;Server&quot;); or (ii) any other
                      player&apos;s Game experience. ANY ATTEMPT BY YOU TO
                      DISRUPT THE GAME CLIENT OR THE SERVICE OR UNDERMINE THE
                      LEGITIMATE OPERATION OF THE GAME MAY BE A VIOLATION OF
                      CRIMINAL AND CIVIL LAWS.
                    </li>

                    <li>
                      Requirements. Before you can use the Service or otherwise
                      operate the Game, you must agree to the provisions of this
                      Terms of Service, and the Privacy Policy and Code of
                      Conduct relating to the Game. Additionally, you
                      acknowledge that You are responsible for any Internet
                      connection and service fees that you incur when accessing
                      the Service or the Game Client.
                    </li>
                  </ol>
                  other method.
                  <br />
                  <br />
                </div>
                <h2>3. Accessing the Service.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      Account Eligibility. You may establish a Service account
                      (“Account”) only if you are (i) a “natural person,” (ii)
                      at least 18 years of age or older, and (iii) of the age of
                      majority in your place of residence and legally able to
                      enter into and be bound by such an agreement according to
                      the laws of your country. By accepting this Terms of
                      Service, you hereby represent and warrant that you meet
                      each of these eligibility requirements.
                    </li>
                    <li>
                      Establishing an Account. When creating or updating an
                      Account on the Service, you are required to provide
                      Wenmoon with certain personal information such as your
                      herotag, wallet address.
                    </li>
                    <li>
                      Account and the Login Information, and you will be
                      responsible for all uses of the Login Information,
                      including purchases, whether or not authorized by you. In
                      the event you become aware of or reasonably suspect any
                      breach of security, including without limitation any loss,
                      theft, or unauthorized use of your Account or disclosure
                      of the Login Information, you must immediately notify
                      Wenmoon support at support@Wenmoon.com.
                    </li>
                    <li>
                      Character Names. You may not select a character name that
                      is offensive, defamatory, vulgar, obscene, sexually
                      explicit, racially, ethnically, or otherwise
                      objectionable, or that misappropriates or infringes the
                      rights of any third party, or falsely indicative of an
                      association with Wenmoon. Wenmoon may (i) modify any name
                      which, in the sole and exclusive judgment of Wenmoon,
                      violates this provision without further notification to
                      you, and (ii) take such other further disciplinary
                      measures, including account termination, for repeated
                      violations, at Wenmoon deems appropriate, in its sole
                      discretion.
                    </li>
                  </ol>

                  <br />
                  <br />
                </div>
                <h2>4. Account Suspension/Cancelation.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      WENMOON MAY SUSPEND, TERMINATE, MODIFY, OR DELETE ANY
                      ACCOUNT(S) AT ANY TIME OR FROM TIME TO TIME, FOR ANY
                      REASON OR FOR NO REASON, WITH OR WITHOUT NOTICE TO YOU.
                      YOU ACKNOWLEDGE THAT ANY AND ALL GAME ASSETS AND/OR
                      ACCOUNTS MAY BE DELETED, ALTERED, MOVED OR TRANSFERRED AT
                      ANY TIME OR FROM TIME TO TIME, BY OR AT THE DIRECTION OF
                      WENMOON, FOR ANY REASON OR NO REASON AT ALL, INCLUDING,
                      WITHOUT LIMITATION FOR ANY VIOLATION OF THIS TERMS OF
                      SERVICE, THE EULA, THE PRIVACY POLICY OR THE CODE OF
                      CONDUCT, AT THE SOLE AND EXCLUSIVE DISCRETION OF WENMOON.
                      WENMOON EXPRESSLY DISCLAIMS ANY VALUE, CASH OR OTHERWISE,
                      ATTRIBUTED TO ANY DATA RESIDING ON SERVERS OPERATED BY
                      WENMOON, INCLUDING, WITHOUT LIMITATION, GAME ASSETS. YOU
                      UNDERSTAND AND AGREE THAT WENMOON HAS THE RIGHT TO REMOVE
                      ANY GAME ASSETS IN WHOLE OR IN PART AT ANY TIME FOR ANY
                      REASON OR NO REASON, WITH OR WITHOUT NOTICE AND WITHOUT
                      LIABILITY OF ANY KIND TO YOU.
                    </li>
                    <li>
                      You may cancel any Account registered to you at any time
                      by following the instructions on the Site. In the event
                      that you terminate or breach this Terms of Service, you
                      will forfeit your right to any and all payments you may
                      have made for pre-purchased Game access or otherwise
                      relating to the Services. You acknowledge and agree that
                      you are not entitled to any refund for any amounts which
                      were prepaid prior to any termination of this Terms of
                      Service.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
                <h2>5. Updates.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      Agreements. Wenmoon may update this Terms of Service as
                      the Game Client and the Service evolve. The next time you
                      use the Service after any such update, you may be prompted
                      to agree to or decline the revised Terms of Service. You
                      must agree to all revisions if you choose to continue
                      using the Service. Wenmoon may also revise other policies,
                      at any time, and the new versions will be available on the
                      Website. If at any point you do not agree to any portion
                      of the then-current version of this Terms of Service, the
                      Privacy Policy, the EULA, the Code of Conduct or any other
                      agreement relating to your use of the Game, you must
                      immediately stop using the Game Client and the Service.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
                <h2>6. Ownership.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      Game Clients and Service. The Game Clients and the Service
                      (including without limitation any titles, computer code,
                      themes, objects, characters, character names, stories,
                      dialogue, catch phrases, concepts, artwork, animations,
                      sounds, musical compositions, audio-visual effects,
                      methods of operation, moral rights, documentation, in-game
                      chat transcripts, character profile information,
                      recordings of any portion of the Game using the Game
                      Client, Server software or otherwise) are copyrighted
                      works owned or licensed for use by Wenmoon and its
                      licensors. Wenmoon reserves all rights in connection with
                      the Game, including without limitation the exclusive right
                      to create derivative works. Any reproduction or
                      redistribution of the Game not in accordance with any
                      policy or agreement, including without limitation the
                      EULA, thirds Terms of Service, the Privacy Policy and the
                      Code of Conduct relating to the Game, is expressly
                      prohibited by law, and may result in severe civil and
                      criminal penalties.
                    </li>
                    <li>
                      Account. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN,
                      YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP
                      OR OTHER PROPERTY INTEREST IN THE ACCOUNT, AND YOU FURTHER
                      ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND TO THE
                      ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE SOLELY
                      TO THE BENEFIT OF WENMOON. You may not purchase, sell,
                      gift or trade any Account, or offer to purchase, sell,
                      gift, or trade any Account, and any such attempt shall be
                      null and void.
                    </li>
                    <li>
                      {' '}
                      Virtual Items. As between you and Wenmoon, Wenmoon owns,
                      has licensed, or otherwise has exclusive rights to all of
                      the content that appears in the Service or otherwise in
                      the Game. You agree that you have no right or title in or
                      to any such content, including without limitation the
                      virtual goods or currency appearing or originating in the
                      Game, or any other attributes associated with the Account
                      or stored on the Server. Wenmoon does not recognize any
                      purported transfers of virtual property executed outside
                      of the Game, or the purported sale, gift or trade in the
                      &quot;real world&quot; of anything that appears or
                      originates in the Game, unless otherwise expressly
                      authorized by Wenmoon in writing. Accordingly, you may not
                      sell in-Game items or currency for &quot;real&quot; money,
                      or exchange those items or currency for value outside of
                      the Game, without the express written permission of
                      Wenmoon. WENMOON SHALL NOT BE LIABLE IN ANY MANNER FOR THE
                      DESTRUCTION, DELETION, MODIFICATION, IMPAIRMENT, HACKING
                      OF OR ANY OTHER DAMAGE OR LOSS OF ANY KIND CAUSED TO ANY
                      OF THE GAME ASSETS, INCLUDING, BUT NOT LIMITED TO,
                      DELETION OF GAME ASSETS UPON THE TERMINATION OR EXPIRATION
                      OF YOUR ACCOUNT. THE SALE OF ACCOUNTS AND VIRTUAL ITEMS
                      AND TRANSFER OF THE ACCOUNTS AND VIRTUAL ITEMS BETWEEN YOU
                      AND ANY OTHER THIRD PARTIES MAY ONLY BE CONDUCTED VIA
                      SERVICES PROVIDED BY WENMOON.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
                <h2>7. Restrictions and Conditions of Use.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      No Violation of Laws. You agree that you will not, in
                      connection with your use of a Game Client or the Service,
                      violate any applicable law or regulation. You will not
                      make available through the Service any material or
                      information that infringes any copyright, trademark,
                      patent, trade secret, or other right of any party
                      (including rights of privacy or publicity). Or otherwise
                      violates any applicable law.
                    </li>
                    <li>
                      Misuse of Service. You may not connect to or use the
                      Service in any way not expressly permitted by this Terms
                      of Service. Without limiting the foregoing, you agree that
                      you will not (i) institute, assist, or become involved in
                      any type of attack, including without limitation denial of
                      service attacks, upon the Service or otherwise attempt to
                      disrupt the Service or any other person&apos;s use of the
                      Service; or (ii) attempt to gain unauthorized access to
                      the Service, Accounts registered to other players, or the
                      computer systems or networks connected to the Service.
                    </li>
                    <li>
                      No Data Mining. You agree that you will not (i) obtain or
                      attempt to obtain any information from the Service or the
                      Game Client using any method not expressly permitted by
                      Wenmoon; (ii) intercept, examine or otherwise observe any
                      proprietary communications protocol used by a Game Client
                      or the Service, whether through the use of a network
                      analyzer, packet sniffer or other device; or (iii) use any
                      third-party software to collect information from or
                      through a Game Client or the Service, including without
                      limitation information about your character, any Account
                      registered to you, virtual items, other players, or other
                      Game data.
                    </li>
                    <li>
                      User Content. &quot;User Content&quot; means any
                      communications, images, sounds, and all the material and
                      information that you upload or transmit through a Game
                      Client or the Service, or that other users upload or
                      transmit, including without limitation any chat text. You
                      hereby grant Wenmoon a perpetual, irrevocable, royalty
                      free, worldwide, non-exclusive, license, including the
                      right to sublicense to third parties, and right to
                      reproduce, fix, adapt, modify, translate, reformat, create
                      derivative works from, manufacture, introduce into
                      circulation, publish, distribute, sell, license,
                      sublicense, transfer, rent, lease, transmit, publicly
                      display, publicly perform, or provide access to
                      electronically, broadcast, communicate to the public by
                      telecommunication, display, perform, enter into computer
                      memory, and use, practice and otherwise exploit such User
                      Content as well as all modified and derivative works
                      thereof. To the extent permitted by applicable laws, you
                      hereby waive any moral rights you have or in the future
                      may have in any User Content.
                    </li>
                    <li>
                      Content Screening and Disclosure. We do not, and cannot,
                      pre-screen or monitor all User Content. However, our
                      representatives may monitor and/or record your
                      communications (including without limitation chat text)
                      when you are using the Service or playing the Game, and
                      you hereby provide your irrevocable consent to such
                      monitoring and recording. You acknowledge and agree that
                      you have no expectation of privacy with concerning the
                      transmission of any User Content, including without
                      limitation chat text or voice communications. We do not
                      assume any responsibility or liability for User Content
                      that is generated by users. We have the right, but not the
                      obligation, in our sole discretion to edit, refuse to
                      post, or remove any User Content. WE ALSO RESERVE THE
                      RIGHT, AT ALL TIMES AND IN OUR SOLE DISCRETION, TO
                      DISCLOSE ANY USER CONTENT AND OTHER INFORMATION (INCLUDING
                      WITHOUT LIMITATION CHAT TEXT, VOICE COMMUNICATIONS,
                      INTERNET PROTOCOL (“IP”) ADDRESSES, AND YOUR PERSONAL
                      INFORMATION) FOR ANY REASON, INCLUDING WITHOUT LIMITATION
                      (A) TO SATISFY ANY APPLICABLE LAW, REGULATION, LEGAL
                      PROCESS OR GOVERNMENTAL REQUEST; (B) TO ENFORCE THE TERMS
                      OF THIS OR ANY OTHER AGREEMENT; (C) TO PROTECT OUR LEGAL
                      RIGHTS AND REMEDIES; (D) WHERE WE FEEL SOMEONE&apos;S
                      HEALTH OR SAFETY MAY BE THREATENED; OR (E) TO REPORT A
                      CRIME OR OTHER OFFENSIVE BEHAVIOR.
                    </li>
                    <li>
                      {' '}
                      Links to Third-Party Websites. The Site may contain links
                      to other independent third-party websites (“Linked
                      Sites”). These Linked Sites are provided solely as a
                      convenience to our users and visitors. Such Linked Sites
                      are not under the control of Wenmoon, and Wenmoon is not
                      responsible for and does not endorse the content of such
                      Linked Sites, including any information or materials
                      contained on such Linked
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
                <h2>8. Disclaimer of Warranties.</h2>
                <div>
                  <br />
                  THE SERVICE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
                  AVAILABLE&quot; BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY
                  KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY
                  IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, UNINTERRUPTED
                  USE, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF
                  TRADE. WENMOON DOES NOT WARRANT THAT YOU WILL BE ABLE TO
                  ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR
                  CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR
                  ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME
                  CLIENT OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL
                  COMPONENTS.
                  <br />
                  <br />
                </div>
                <h2>
                  9. Limitation of Liability; Sole and Exclusive Remedy;
                  Indemnification.
                </h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      WENMOON IS NOT RESPONSIBLE FOR DAMAGES ARISING OUT OF YOUR
                      USE OF THE GAME CLIENT OR THE SERVICE OR YOUR INABILITY TO
                      USE THE GAME CLIENT OR THE SERVICE. IN NO CASE SHALL
                      WENMOON BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT,
                      PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR USE OF
                      (OR FAILURE TO USE) THE SERVICE, THE GAME CLIENT OR ANY
                      ASPECT OF THE GAME. YOU ACKNOWLEDGE AND AGREE THAT YOUR
                      SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH WENMOON IS
                      TO STOP USING THE SERVICE AND THE GAME CLIENT, AND TO
                      CANCEL ALL ACCOUNTS REGISTERED TO YOU.
                    </li>
                    <li>
                      You agree to indemnify, defend and hold Wenmoon, its
                      subsidiaries, parent companies, affiliates, licensors,
                      licensees, advisors and partners harmless from and against
                      any claim, demand, damages or other losses, including
                      reasonable attorneys&apos; fees, asserted by any
                      third-party resulting from or arising out of (i) your use
                      of the Service, the Game Client or otherwise relating to
                      the Game or (i) any breach by you of this Terms of
                      Service, the EULA or the Privacy Policy or Code of Conduct
                      relating to the Game.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
                <h2>10. Dispute Resolution and Governing Law.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      Informal Negotiations. Disputes can be expensive and time
                      consuming for both parties. In an effort to accelerate
                      resolution and reduce the cost of any dispute, controversy
                      or claim related to this Agreement (&quot;Dispute&quot;),
                      you and Wenmoon agree to first attempt to negotiate any
                      Dispute (except those Disputes expressly provided below in
                      paragraph 11(b) (i) through (iii) or as contemplated in
                      11(e)) informally for at least thirty (30) days before
                      initiating any arbitration or court proceeding.
                      Negotiations will begin upon written notice. Wenmoon will
                      notify you through the contact information you provided at
                      registration. You will send your notice to Coliemore
                      House, Coliemore Road, Dalkey, Co. Dublin, A96 A8D5,
                      Ireland and by email to privacy@wenmoonstudios.com
                    </li>
                    <li>
                      Binding Arbitration. If the parties are unable to resolve
                      a Dispute through negotiations, the Dispute (except those
                      expressly excluded below) will be finally and exclusively
                      resolved by binding arbitration as contemplated herein
                      which shall be final and binding upon You and Wenmoon. YOU
                      UNDERSTAND THAT ABSENT THIS PROVISION, YOU OTHERWISE WOULD
                      HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
                      arbitration shall be commenced and conducted under the
                      Commercial Arbitration Rules of the American Arbitration
                      Association (&quot;AAA&quot;) and, where appropriate, the
                      AAA&apos;s Supplementary Procedures for Consumer Related
                      Disputes (&quot;AAA Consumer Rules&quot;), both of which
                      are available that the AAA website www.adr.org, as updated
                      from time to time. The determination of whether a Dispute
                      is subject to arbitration shall be governed by the Federal
                      Arbitration Act and determined by a court rather than an
                      arbitrator. Your arbitration fees and your share of
                      arbitrator compensation shall be governed by the AAA Rules
                      and, where appropriate, limited by the AAA Consumer Rules.
                      If such costs are determined by the arbitrator to be
                      excessive, Wenmoon will pay all arbitration fees and
                      expenses. The arbitration may be conducted in person,
                      through the submission of documents, by phone or online.
                      The arbitrator will make a decision in writing, but need
                      not provide a statement of reasons unless requested by a
                      party. The arbitrator must follow applicable law, and any
                      award may be challenged if the arbitrator fails to do so.
                      Except as otherwise provided in this Terms of Service, you
                      and Wenmoon may litigate in court to compel arbitration,
                      stay proceeding pending arbitration, or to confirm,
                      modify, vacate or enter judgment on the award entered by
                      the arbitrator. Notwithstanding the preceding, you and
                      Wenmoon agree that the following Disputes are not subject
                      to the above provisions concerning informal negotiations
                      and binding arbitration: (i) any Dispute seeking to
                      enforce or protect, or concerning the validity of, any of
                      Your or Wenmoon’s (or its affiliates or licensee’s)
                      intellectual property rights; (ii) any Dispute related to,
                      or arising from, allegations of theft, piracy, invasion of
                      privacy or unauthorized use; and (iii) any claim for
                      injunctive relief. For such Disputes as listed in
                      11(b)(i), (ii) and (iii), Wenmoon may at its sole
                      discretion have the Dispute tried by a court in your
                      jurisdiction to obtain a judgment enforceable in your
                      jurisdiction.
                    </li>

                    <li>
                      Restrictions. You and Wenmoon agree that any arbitration
                      shall be limited to the Dispute between Wenmoon and you
                      individually. YOU ACKNOWLEDGE AND AGREE THAT (I) NO
                      ARBITRATION SHALL BE JOINED WITH ANY OTHER PARTY; (II)
                      THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE
                      ARBITRATED ON A CLASS ACTION BASIS OR TO UTILIZE CLASS
                      ACTION PROCEDURES; AND (III) THERE IS NO RIGHT OR
                      AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED
                      REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC OR
                      ANY OTHER PERSONS.
                    </li>
                    <li>
                      Governing Law and Jurisdiction. Except as expressly
                      provided otherwise, this Terms of Service shall be is
                      governed by, and will be construed under, the laws of the
                      United States of America and the laws of the State of New
                      York, without regard to choice of law principles. Any
                      arbitration proceeding under this Terms of Service shall
                      be conducted in New York City, New York, United States of
                      America. Any Dispute not subject to arbitration shall be
                      decided by a court of competent jurisdiction with New York
                      City, New York, United States of America, and you and
                      Wenmoon hereby irrevocably consent to the exclusive
                      personal jurisdiction of that court. The application of
                      the United Nations Convention on Contracts for the
                      International Sale of Goods is expressly excluded. For our
                      customers who purchased a license to the Game in, and are
                      a resident of, Canada, Australia, Singapore, New Zealand
                      or outside the Unites States, other laws may apply with
                      respect to the mandatory arbitration provisions set forth
                      above, and, in such an event. You agree that such laws
                      shall only affect this Terms of Service only to the extent
                      required by such jurisdiction.
                    </li>
                    <li>
                      Equitable Relieve. You hereby acknowledge and agree that
                      (i) Wenmoon would be irreparably damaged if the terms of
                      this Terms of Service, the EULA, the Privacy Policy or the
                      Code of Conduct were breached, threatened to be breached
                      or not specifically enforced; and (ii) in addition to such
                      other remedies available to it at law, in equity or
                      otherwise, Wenmoon is hereby entitled, without providing
                      bond, other security, or proof of damages, to seek
                      temporary restraining orders and permanent injunctive
                      relieve against Your anticipated, actual or continued
                      breach of Your duties and obligations as contemplated
                      under this Terms of Service, the EULA, the Privacy Policy
                      or the Code of Conduct. In the event any litigation or
                      other action is brought by either party in connection with
                      this Agreement, the prevailing party in such litigation
                      shall be entitled to recover from the other party all the
                      costs, attorneys’ fees and other expenses reasonably
                      incurred by such prevailing party in such litigation or
                      action.
                    </li>
                    <li>
                      Severability. You and Wenmoon agree that if any portion of
                      this Agreement is found illegal or unenforceable, that
                      portion shall be severed and the remainder of the Section
                      and the rest of this Terms of Service shall be given full
                      force and effect.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
                <h2>11. Third Party Advertising.</h2>
                <div>
                  <br />
                  The Game Client and the Service may incorporate technology
                  from 3rd parties that enables in-game advertising, and the
                  display of other similar in-game objects, which are downloaded
                  temporarily to your personal computer and replaced during
                  online game play. As part of this process, Wenmoon or third
                  parties may collect some information about the Game and the
                  advertisements delivered to You, as well as standard
                  information that is sent when your personal computer or game
                  console connects to the Internet including your IP address.
                  Wenmoon and 3rd parties may use this information to transmit
                  and measure in-game advertising, as well as to improve the
                  products and services offered. None of the information
                  collected by Wenmoon will be used to identify you personally.
                  The trademarks and copyrighted material contained in all
                  in-game advertising are the property of the respective owners.
                  <br />
                  <br />
                </div>
                <h2>12. Access to Computer Data.</h2>
                <div>
                  <br />
                  Pursuant to United States Code (U.S.C.), Title 18, Part 1,
                  Section 2701 Part(c), we reserve the right to scan your system
                  to ensure that your connection does not provide a network or
                  security risk to us, our servers, or our users. In addition,
                  we may collect information about your computer system (such
                  as: system specs, software versions, builds) to enable
                  developers to optimize the game and/or to troubleshoot
                  technical issues.
                  <br />
                  <br />
                </div>
                <h2>13. General.</h2>
                <div>
                  <br />
                  <ol>
                    <li>
                      TERMS OF SERVICE Revisions. Wenmoon reserves the right, at
                      its sole and absolute discretion, to change, modify, add
                      to, supplement or delete, at any time, any of the terms
                      and conditions of this Terms of Service, effective with or
                      without prior notice; provided, however, that material
                      changes to this Terms of Service will not be applied
                      retroactively.
                    </li>
                    <li>
                      Assignment and Beneficiaries. Wenmoon may assign all or
                      any portion of this Terms of Service and its rights and
                      obligations hereunder, in whole or in part, to any person
                      or entity at any time with or without your consent and
                      without notice to you. You may not assign all or any
                      portion of this Terms of Service Any unauthorized
                      assignment by you shall be null and void. This Terms of
                      Service will inure to the benefit of Wenmoon and its
                      successors and assigns. Each Wenmoon’s subsidiaries and
                      other affiliates are intended to be third party
                      beneficiaries of the provisions of this Terms of Service.
                    </li>
                    <li>
                      Attorneys&apos; Fees. In the event any arbitration or
                      litigation is brought by either party in connection with
                      this Terms of Service, the prevailing party in such matter
                      shall be entitled to recover from the other party all the
                      reasonable costs, attorneys&apos; fees and other expenses
                      incurred by such prevailing party in the litigation.
                    </li>
                    <li>
                      Entire Agreement. This Terms of Service, including the
                      documents expressly incorporated by reference herein,
                      constitutes the entire agreement between You and Wenmoon
                      with respect to the Game Client and the Service and
                      supersedes all prior or contemporaneous communications,
                      whether electronic, oral or written, between you and us
                      with respect to the Service; provided, however, that this
                      Terms of Service shall coexist with, and shall not
                      supersede, the EULA, the Privacy Policy or the Code of
                      Conduct for the Game. In the event of a conflict or
                      inconsistency between this Terms of Service and the EULA,
                      the terms of this Terms of Service shall control.
                    </li>
                    <li>
                      Equitable Remedies. You hereby agree that Wenmoon would be
                      irreparably damaged if the terms of this Terms of Service
                      were not specifically enforced, and therefore you agree
                      that Wenmoon shall be entitled, without bond, other
                      security, or proof of damages, to appropriate equitable
                      remedies with respect to breaches of this Terms of
                      Service, in addition to such other remedies as we may
                      otherwise have available to us under applicable laws.
                    </li>
                    <li>
                      Force Majeure. Wenmoon shall not be liable for any delay
                      or failure to perform resulting from causes outside the
                      reasonable control of Wenmoon, including without
                      limitation any failure to perform hereunder due to
                      unforeseen circumstances or cause beyond Wenmoon’s control
                      such as acts of God, war, terrorism, riots, embargoes,
                      acts of civil or military authorities, fire, floods,
                      accidents, strikes, or shortages of transportation
                      facilities, fuel, energy, labor or materials.
                    </li>
                    <li>
                      Notices. All notices given by you or required under this
                      Terms of Service shall be in writing and addressed to:
                      Coliemore House, Coliemore Road, Dalkey, Co. Dublin, A96
                      A8D5, Ireland. ATTN: Legal Department.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tos;
