import React from "react";
import { useLocation } from "react-router-dom";
import routes, { routeNames } from "routes";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const loc = useLocation();
  const { pathname, search } = useLocation();
  let contextName = "default";
  if (pathname.replace("/", "-") !== "-") {
    contextName = pathname.replace(/\/(.*)\/(.*)\/(.*)/g, "$1-id-$3");
    contextName = contextName.replace(/\//g, "-");
    contextName = contextName.replace(/(marketplace-)(\d+)/g, "$1-id");
    contextName = contextName.replace(/(jobs-)(\d+)/g, "$1-id");
  }
  return (
    <div className="container body-wrapper">
      <Navbar />
      <main
        className={
          "mainz __mainz-context-" +
          contextName +
          " d-flex flex-column flex-grow-1"
        }
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
