import React from 'react';

const SocialLinks = () => {
  return (
    <div className='social-links'>
      <a
        href='https://t.me/AOZalmoxis'
        target='_blank'
        rel='noreferrer'
        className='social-tg'
      ></a>
      <a
        href='https://twitter.com/AgeOfZalmoxis'
        target='_blank'
        rel='noreferrer'
        className='social-tw'
      ></a>

      <a
        href='https://discord.gg/j4Br5886Rx'
        target='_blank'
        rel='noreferrer'
        className='social-di'
      ></a>
    </div>
  );
};

export default SocialLinks;
