import React from 'react';

const Bridge = () => {
  return (
    <div style={{width:'100%',height:'800px'}}>
      <iframe src='https://widget.xp.network/?widget=true&background=161517&panelBackground=262426&modalBackground=262426&color=c3c2c3&fontSize=16&btnColor=bdac91&btnBackground=161517&btnRadius=9&fontFamily=Open Sans&chains=Polygon-Elrond&from=Elrond&to=Polygon&cardBackground=1e222d&cardBackgroundBot=1e222d&cardColor=1e222d&cardRadius=25&secondaryColor=bdac91&accentColor=bdac91&borderColor=988b8b&iconColor=3e64ed&tooltipBg=1D212A&tooltipColor=ffffff&wallets=WalletConnect-Maiar-TrustWallet-MaiarExtension-MetaMask&bridgeState=undefined&showLink=false&affiliationFees=1' frameBorder='0'  width="100%" height="100%" id="xpnetWidget"></iframe>
      <script src='https://widget.xp.network/wscript.js'>
      </script>
    </div>
  );
};

export default Bridge;
