import {
  getAccountNftsCount,
  getAddressTransactionsCount,
  getAozKosonSupply,
  getCirculatingLandChestSupply,
  getKosonBalance,
  getKosonCirculatingSupply,
  getKosonHoldersSnapshot,
  getKosonTotalSupply,
  getNftsSnapshot,
  getNftSummoningCounts,
  getStakedLandChestsCounts,
  getTotalNftSupply,
} from "apiRequests";
import React from "react";
import BigNumber from "bignumber.js";
// import { validNFTTickers } from 'config';
// import MintingStatistics from "./../Marketplace/MintingStatistics";
import {
  landChestSaleContractAddress,
  landChestStakingContractAddress,
  landChestTokenIdentifier,
  marketplaceContractAddress,
  mintingContractAddress,
  nftStakingContractAddress,
  nftSwapsContractAddress,
  saleContractAddress,
  stakePoolNames,
  stakingPools,
  summoningContractAddress,
} from "config";
import { ReactComponent as KossonsSymbol } from "./../../assets/img/kossons-symbol.svg";
import Spinner from "components/UI/Spinner";
import { SmartContractStats } from "types";

const KeyStats = () => {
  const DAILY_SOUL_NFT_STAKING_REWARD = 18442.6229508;
  const DAILY_LAND_CHEST_STAKING_REWARDS: any = {
    "LANDCHEST-1f6b76-01": 1294.52054795,
    "LANDCHEST-1f6b76-02": 5178.08219178,
    "LANDCHEST-1f6b76-03": 7767.12328767,
    "LANDCHEST-1f6b76-04": 11650.6849315,
  };
  const LAND_CHEST_TO_LAND_PLOT_MAPPING: any = {
    "LANDCHEST-1f6b76-01": "LANDTOKENS-07db50-05",
    "LANDCHEST-1f6b76-02": "LANDTOKENS-07db50-04",
    "LANDCHEST-1f6b76-03": "LANDTOKENS-07db50-03",
    "LANDCHEST-1f6b76-04": "LANDTOKENS-07db50-02",
  };
  const [nftSnapshot, setNftSnapshot] = React.useState([]);
  const [kosonSnapshot, setKosonSnapshot] = React.useState([]);
  const [aozKosonWallets, setAozKosonWallets] = React.useState([]);
  const [kosonCirculatingSupply, setKosonCirculatingSupply] = React.useState(0);
  const [kosonTotalSupply, setKosonTotalSupply] = React.useState(0);
  const [kosonBurnedSupply, setKosonBurnedSupply] = React.useState(0);
  const [kosonLockedSupply, setKosonLockedSupply] = React.useState(0);
  const [totalNftSupply, setTotalNftSupply] = React.useState(0);
  const [totalSummonedNftSupply, setTotalSummonedNftSupply] = React.useState(0);
  const [nftSupplies, setNftSupplies] = React.useState<any[]>([]);
  const [landChestSupply, setLandChestSupply] = React.useState<any[]>([]);
  const [stakedLandChestsSupply, setStakedLandChestsSupply] = React.useState<
    any[]
  >([]);
  const [totalStakedNfts, setTotalStakedNfts] = React.useState(0);
  const [
    totalSmartContractTransactionsCount,
    setTotalSmartContractTransactionsCount,
  ] = React.useState<number>();
  const [smartContractStats, setSmartContractStats] =
    React.useState<SmartContractStats[]>();
  const [nftStakingKosonAllocation, setNftStakingKosonAllocation] =
    React.useState(0);
  const [landChestStakingKosonAllocation, setLandChestStakingKosonAllocation] =
    React.useState(0);
  const [inputSoulTypeIdentifier, setInputSoulTypeIdentifier] =
    React.useState("");
  const [stakingPoolSupplies, setStakingPoolsSupplies] = React.useState([
    0, 0, 0, 0, 0, 0,
  ]);

  const NftSuppliesMappingImg: any = {
    AETHERSOUL: "Origin Aether.png",
    AIRSOUL: "Origin Air.png",
    DEATHSOUL: "Origin Death 7th.png",
    EARTHSOUL: "Origin Earth.png",
    FIRESOUL: "Origin Fire.png",
    LIFESOUL: "Origin Life.png",
    ORIGIN: "Origin not-migrated.png",
    WATERSOUL: "Origin Water.png",
    SAETHSOUL: "Summoned Aether.png",
    SAIRSOUL: "Summoned Air.png",
    SEARTHSOUL: "Summoned Earth.png",
    SFIRESOUL: "Summoned Fire.png",
    SLIFESOUL: "Summoned Life.png",
    SWATERSOUL: "Summoned Water.png",
  };

  const LandChestNftSuppliesMappingImg: any = {
    "LANDCHEST-1f6b76-01": "Continental Chest.png",
    "LANDCHEST-1f6b76-02": "Steepe Chest.png",
    "LANDCHEST-1f6b76-03": "Panonic Chest.png",
    "LANDCHEST-1f6b76-04": "Pontic Chest.png",
  };

  const validNFTTickersMaping = [
    "AIRSOUL-1d1c12",
    "EARTHSOUL-7719e9",
    "FIRESOUL-7719e9",
    "LIFESOUL-fbbc7b",
    "AETHERSOUL-fbbc7b",
    "WATERSOUL-2d8eeb",

    "SAIRSOUL-48f4fe",
    "SEARTHSOUL-01c5d1",
    "SFIRESOUL-01c5d1",
    "SLIFESOUL-01c5d1",
    "SAETHSOUL-48f4fe",
    "SWATERSOUL-9fbd17",

    "ORIGIN-fe949f",
    "DEATHSOUL-9fbd17",

    "LANDCHEST-1f6b76-01",
    "LANDCHEST-1f6b76-02",
    "LANDCHEST-1f6b76-03",
    "LANDCHEST-1f6b76-04",
  ];

  React.useEffect(() => {
    getNftsSnapshot().then((res) => {
      if (res.success) {
        setNftSnapshot(res.data);
        setTotalNftSupply(
          res.data.filter((d: any) => d.ownerAddress !== mintingContractAddress)
            .length
        );
        let supplies = [];
        let summonedNftSuply = 0;
        for (var i = 0; i < validNFTTickersMaping.length; i++) {
          let supply = res.data.filter(
            (d: any) =>
              d.tokenIdentifier === validNFTTickersMaping[i] &&
              d.ownerAddress !== mintingContractAddress
          ).length;
          supplies.push(supply);
          if (i > 5 && validNFTTickersMaping[i] !== "ORIGIN-fe949f") {
            // is summoned soul
            summonedNftSuply += supply;
          }
        }
        setTotalSummonedNftSupply(summonedNftSuply);
        setNftSupplies(supplies);
        // setTotalStakedNfts(res.data.filter((d: any) => d.ownerAddress === nftStakingContractAddress).length);
      }
    });
  }, []);

  React.useEffect(() => {
    fetchStakingSupply().then(() => console.log("staking supply initialized"));
  }, []);

  React.useEffect(() => {
    getAccountNftsCount(nftStakingContractAddress).then((res) => {
      if (res.success) {
        setTotalStakedNfts(res.data);
      }
    });
  }, []);

  React.useEffect(() => {
    getKosonHoldersSnapshot().then((res) => {
      if (res.success) {
        setKosonSnapshot(res.data);
      }
    });
  }, []);

  React.useEffect(() => {
    getKosonTotalSupply().then((res) => {
      if (res.success) {
        setKosonTotalSupply(parseFloat(res.data.toFixed(2)));
        setKosonBurnedSupply(parseFloat((270_000_000 - res.data).toFixed(2)));
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      nftStakingKosonAllocation === 0 ||
      landChestStakingKosonAllocation === 0
    )
      return;
    getKosonCirculatingSupply().then((res) => {
      if (res.success) {
        let circulatingSupply = parseFloat(res.data.toFixed(2));
        setKosonCirculatingSupply(circulatingSupply);
      }
    });
  }, [nftStakingKosonAllocation, landChestStakingKosonAllocation]);

  React.useEffect(() => {
    getAozKosonSupply().then((res) => {
      if (res.success) {
        setAozKosonWallets(res.data.filter((d: any) => d.shouldDisplay));
        const lockedSupply =
          res.data.reduce(
            (prev: number, current: any) => (prev += current.amount),
            0
          ) - 156892.27;
        setKosonLockedSupply(parseFloat(lockedSupply.toFixed(2)));
      }
    });
  }, []);

  React.useEffect(() => {
    Promise.all([
      getStakedLandChestsCounts(),
      // getCirculatingLandChestSupply()
    ]).then(([stakedLandChests]) => {
      if (stakedLandChests.success) {
        setStakedLandChestsSupply(stakedLandChests.data);
        setLandChestSupply([0, 0, 0, 0]);
      }
    });
  }, []);

  React.useEffect(() => {
    Promise.all([
      getKosonBalance()(nftStakingContractAddress),
      getKosonBalance()(landChestStakingContractAddress),
    ]).then(([nftStakingBalance, landStakingBalance]) => {
      setNftStakingKosonAllocation(
        new BigNumber(nftStakingBalance.data[0].balance)
          .div(new BigNumber(10).pow(18))
          .toNumber()
      );
      setLandChestStakingKosonAllocation(
        new BigNumber(landStakingBalance.data[0].balance)
          .div(new BigNumber(10).pow(18))
          .toNumber()
      );
    });
  }, []);

  React.useEffect(() => {
    Promise.all([
      getAddressTransactionsCount(saleContractAddress),
      getAddressTransactionsCount(mintingContractAddress),
      getAddressTransactionsCount(marketplaceContractAddress),
      getAddressTransactionsCount(nftStakingContractAddress),
      getAddressTransactionsCount(nftSwapsContractAddress),
      getAddressTransactionsCount(summoningContractAddress),
      getAddressTransactionsCount(landChestSaleContractAddress),
      getAddressTransactionsCount(landChestStakingContractAddress),
    ]).then((results) => {
      if (
        !results
          .map((r) => r.success)
          .reduce((prev, current) => prev && current, true)
      ) {
        return;
      }
      const resultsMapping: SmartContractStats[] = [];
      resultsMapping.push({
        scAddress: saleContractAddress,
        scDisplayName: "Seed/Private Sale Smart Contract",
        transactionsCount: results[0].data,
      });
      resultsMapping.push({
        scAddress: mintingContractAddress,
        scDisplayName: "ORIGIN soul mint and migrate Smart Contract",
        transactionsCount: results[1].data,
      });
      resultsMapping.push({
        scAddress: marketplaceContractAddress,
        scDisplayName: "NFT Marketplace Smart Contract",
        transactionsCount: results[2].data,
      });
      resultsMapping.push({
        scAddress: nftStakingContractAddress,
        scDisplayName: "Soul NFT staking Smart Contract",
        transactionsCount: results[3].data,
      });
      resultsMapping.push({
        scAddress: nftSwapsContractAddress,
        scDisplayName: "NFT Swaps Smart Contract",
        transactionsCount: results[4].data,
      });
      resultsMapping.push({
        scAddress: summoningContractAddress,
        scDisplayName: "Summoning Process Smart Contract",
        transactionsCount: results[5].data,
      });
      resultsMapping.push({
        scAddress: landChestSaleContractAddress,
        scDisplayName: "Land Chest Sale Smart Contract",
        transactionsCount: results[6].data,
      });
      resultsMapping.push({
        scAddress: landChestStakingContractAddress,
        scDisplayName: "Land Chest Staking Smart Contract",
        transactionsCount: results[7].data,
      });
      setSmartContractStats(resultsMapping);
      setTotalSmartContractTransactionsCount(
        results.map((r) => r.data).reduce((prev, current) => prev + current, 0)
      );
    });
  }, []);

  const fetchStakingSupply = async () => {
    let supplies = [];
    for (var i = 0; i < 6; i++) {
      let supply = await getKosonBalance()(stakingPools[i]);
      let balance = supply.data[0].balance;
      supplies.push(
        new BigNumber(balance).div(new BigNumber(10).pow(18)).toNumber()
      );
    }
    setStakingPoolsSupplies(supplies);
  };

  const getTotalStakedByTokenIdentifier = (tokenIdentifier: string) => {
    if (tokenIdentifier.startsWith(landChestTokenIdentifier)) {
      const chestSupply =
        stakedLandChestsSupply.filter(
          (s) => s.identifier === tokenIdentifier
        )[0]?.supply ?? 0;
      const plotToken = LAND_CHEST_TO_LAND_PLOT_MAPPING[tokenIdentifier];
      const landPlotsSupply =
        stakedLandChestsSupply.filter((s) => s.identifier === plotToken)[0]
          ?.supply ?? 0;
      return chestSupply + landPlotsSupply;
    }
    return nftSnapshot.filter(
      (d: any) =>
        d.ownerAddress === nftStakingContractAddress &&
        d.tokenIdentifier === tokenIdentifier
    ).length;
  };

  const handleCheckSouls = async () => {
    let spl = inputSoulTypeIdentifier.split("-");
    let token = `${spl[0]}-${spl[1]}`;
    if (!validNFTTickersMaping.includes(token) || spl.length != 3) {
      window.alert(
        "This NFT ticker is either invalid, either does not belong to the AgeOfZalmoxis ecosystem."
      );
      return;
    }
    let nftData = await getNftSummoningCounts(inputSoulTypeIdentifier);
    if (!nftData.success) {
      window.alert("Something went wrong, did you input the identifier right?");
      return;
    }
    let summoningCounts = nftData.data[inputSoulTypeIdentifier];
    let message = `NFT with identifier ${inputSoulTypeIdentifier} has been used ${summoningCounts[0]} times out of 6 for common summons`;
    let index = validNFTTickersMaping.indexOf(token);
    if (index >= 0 && index < 7) {
      message += ` and ${summoningCounts[1]} times for Death Soul summoning`;
    }
    window.alert(message);
  };

  return nftSnapshot.length === 0 || kosonSnapshot.length === 0 ? (
    <div className="keystats-page">
      <div
        className="container features-container px-5 px-lg-0"
        style={{ height: "100vh" }}
      >
        <Spinner />
      </div>
    </div>
  ) : (
    <div className="keystats-page">
      <div className="container features-container px-5 px-lg-0">
        {/*<div className='row mb-2'>
          <div className='col'>
            <h1>Minting Statistics</h1>

            <div className='row bloc-content'>
                <div className='col-12 col-lg-6'>
                  <div className='row'>
                    <div className='col-12 p-3'>
                      <MintingStatistics
                        title='Air Soul'
                        thumbnailImageName='air'
                        supply={'577'}
                        // remaining={mintingStats.remainingAir}
                        elementNumber={1}
                      />
                    </div>
                    <div className='col-12 p-3'>
                      <MintingStatistics
                        title='Fire Soul'
                        thumbnailImageName='fire'
                        supply={'577'}
                        // remaining={mintingStats.remainingFire}
                        elementNumber={2}
                      />
                    </div>
                    <div className='col-12 p-3'>
                      <MintingStatistics
                        title='Water Soul'
                        thumbnailImageName='water'
                        supply={'577'}
                        // remaining={mintingStats.remainingWater}
                        elementNumber={3}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-6'>
                  <div className='row'>
                    <div className='col-12 p-3'>
                      <MintingStatistics
                        title='Earth Soul'
                        thumbnailImageName='earth'
                        supply={'577'}
                        // remaining={mintingStats.remainingEarth}
                        elementNumber={4}
                      />
                    </div>
                    <div className='col-12 p-3'>
                      <MintingStatistics
                        title='Life Soul'
                        thumbnailImageName='life'
                        supply={'577'}
                        // remaining={mintingStats.remainingLife}
                        elementNumber={5}
                      />
                    </div>
                    <div className='col-12 p-3'>
                      <MintingStatistics
                        title='Aether Soul'
                        thumbnailImageName='aether'
                        supply={'577'}
                        // remaining={mintingStats.remainingAether}
                        elementNumber={6}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>*/}
        <div className="row pb-5">
          <div className="col-12">
            <h1>Statistics</h1>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-12">
            <div className="section-page-head">
              <span className="bloc-logo-koson mb-2 mb-lg-0"></span>
              <span className="bloc-chevron-right mx-2 d-none d-lg-block"></span>
              <span className="bloc-head-title pr-3">
                Soul summonings check
              </span>
              <span className="bloc-head-separator"></span>
              <span className="bloc-head-subtitle">
                Check the summoning status of a soul NFT
              </span>
            </div>
          </div>
        </div>

        <div className="row bloc-table-row">
          <div className="col-12 col-lg-12 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title">
                Input soul identifier (e.g. AETHERSOUL-fbbc7b-01bf):
              </div>
              <div className="bloc-table-value">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-10">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          border: "none",
                          background: "#1e1e20",
                          color: "#fbc9a7",
                          padding: "15px",
                        }}
                        onChange={(e) =>
                          setInputSoulTypeIdentifier(e.target.value)
                        }
                        placeholder="AETHERSOUL-fbbc7b-01bf"
                      />
                    </div>
                    <div className="col-12 col-lg-2 d-flex justify-content-center">
                      {/* <button className='btn btn-primary' onClick={handleCheckSouls}>Check</button> */}
                      <div
                        className="btz btz-sm borderz d-inline"
                        onClick={handleCheckSouls}
                      >
                        <span className="outer">Check</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-separator my-5 py-3"></div>
        <div className="row pb-5">
          <div className="col-12">
            <div className="section-page-head">
              <span className="bloc-logo-koson mb-2 mb-lg-0"></span>
              <span className="bloc-chevron-right mx-2 d-none d-lg-block"></span>
              <span className="bloc-head-title pr-3">KOSON Stats</span>
              <span className="bloc-head-separator"></span>
              <span className="bloc-head-subtitle">
                Additional informations
              </span>
            </div>
          </div>
        </div>
        <div className="row bloc-table-row">
          <div className="col-12 col-lg-2 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title">Initial supply:</div>
              <div className="bloc-table-value">
                {(270000000).toLocaleString("en-US")}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title">Total supply</div>
              <div className="bloc-table-value">
                {kosonTotalSupply.toLocaleString("en-US")}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title color-main1">
                Treasury supply
              </div>
              <div className="bloc-table-value">
                {kosonLockedSupply.toLocaleString("en-US")}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title">Circulating supply</div>
              <div className="bloc-table-value">
                {kosonCirculatingSupply.toLocaleString("en-US")}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title">Burned supply</div>
              <div className="bloc-table-value">
                {kosonBurnedSupply.toLocaleString("en-US")}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 bloc-table">
            <div className="bloc-table-content">
              <div className="bloc-table-title">Unique holders</div>
              <div className="bloc-table-value">
                {kosonSnapshot
                  .filter((ks: any) => ks.unlockedAmountFull !== "0")
                  .length.toLocaleString("en-US")}
              </div>
            </div>
          </div>
          {stakePoolNames.map((pn, i) => (
            <div className="col-12 col-lg-2 bloc-table">
              <div className="bloc-table-content">
                <div className="bloc-table-title">
                  {stakePoolNames[i]} Pool Supply
                </div>
                <div className="bloc-table-value">
                  {stakingPoolSupplies[i].toLocaleString()}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="main-separator my-5 py-3"></div>
        {/* Wallets row */}
        <div className="row pb-5">
          <div className="col-12">
            <div className="section-page-head">
              <span className="bloc-logo-aoz mb-2 mb-lg-0"></span>
              <span className="bloc-chevron-right mx-2 d-none d-lg-block"></span>
              <span className="bloc-head-title pr-3">Age of Zalmoxis</span>
              <span className="bloc-head-separator"></span>
              <span className="bloc-head-subtitle">
                Token Allocation Wallets
              </span>
            </div>
          </div>
        </div>
        <div className="row bloc-table-row">
          {aozKosonWallets.map((wallet: any, index) => (
            <div className="col-12 col-lg-2 bloc-table" key={index + 100}>
              <div className="bloc-table-content">
                <div className="bloc-table-title">
                  <a
                    href={`https://explorer.elrond.com/accounts/${wallet.address}/tokens`}
                    target="_blank"
                    className={index === 5 ? "color-main1" : ""}
                  >
                    {wallet.displayName}
                  </a>
                </div>
                <div
                  className={
                    index === 5
                      ? "bloc-table-value bloc-table-cus"
                      : "bloc-table-value"
                  }
                >
                  {wallet.amount.toLocaleString("en-US")}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="main-separator my-5 py-3"></div>
        {nftSupplies.length > 0 &&
        landChestSupply.length > 0 &&
        stakedLandChestsSupply.length > 0 ? (
          <>
            <div className="row pb-5">
              <div className="col-12">
                <div className="section-page-head">
                  <span className="bloc-logo-aoz mb-2 mb-lg-0"></span>
                  <span className="bloc-chevron-right mx-2 d-none d-lg-block"></span>
                  <span className="bloc-head-title pr-3">NFT stats</span>
                  <span className="bloc-head-separator"></span>
                  <span className="bloc-head-subtitle">
                    Soul stats ({totalNftSupply.toLocaleString()} in total -{" "}
                    {totalSummonedNftSupply.toLocaleString()} summoned;{" "}
                    {totalStakedNfts.toLocaleString()} staked)
                  </span>
                </div>
              </div>
            </div>
            <div className="row bloc-table-row justify-content-center mb-5">
              {validNFTTickersMaping.map((token, i) => (
                <div
                  className={
                    i >= 12
                      ? i >= 14
                        ? "col-12 col-lg-3 bloc-table"
                        : "col-12 col-lg-6 bloc-table"
                      : "col-12 col-lg-2 bloc-table"
                  }
                  key={i + 200}
                >
                  <div className="bloc-table-content d-flex">
                    <div
                      className={
                        i >= 12 && i <= 13 ? "bloc-table-cus2" : "w-100"
                      }
                    >
                      {i <= 13 ? (
                        i === 12 ? (
                          <div className="bloc-table-title">
                            {token.split("-")[0]} (not migrated)
                          </div>
                        ) : (
                          <div className="bloc-table-title">
                            {token.split("-")[0]}
                          </div>
                        )
                      ) : (
                        <div className="bloc-table-title">
                          {LandChestNftSuppliesMappingImg[token].replace(
                            ".png",
                            ""
                          )}
                        </div>
                      )}
                      {token === validNFTTickersMaping[13] ? (
                        <div
                          className="bloc-table-value"
                          style={{ color: "red" }}
                        >
                          {nftSupplies[i]}{" "}
                          {`(${getTotalStakedByTokenIdentifier(token)} staked)`}
                        </div>
                      ) : token === validNFTTickersMaping[12] ? (
                        <div className="bloc-table-value">{nftSupplies[i]}</div>
                      ) : i >= 14 ? (
                        <div className="bloc-table-value">
                          {" "}
                          {`${getTotalStakedByTokenIdentifier(token)} staked`}
                        </div>
                      ) : (
                        <div className="bloc-table-value">
                          {nftSupplies[i]}{" "}
                          {`(${getTotalStakedByTokenIdentifier(token)} staked)`}
                        </div>
                      )}
                    </div>
                    <div className="w-33 px-3">
                      {i <= 13 ? (
                        <img
                          src={`/nftSupplies/${
                            NftSuppliesMappingImg[token.split("-")[0]]
                          }`}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`/nftSupplies/${LandChestNftSuppliesMappingImg[token]}`}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="row bloc-table-row justify-content-center mb-5">
            <div className="col-12 col-lg-12 bloc-table">
              <div
                className="bloc-table-content bloc-table-value d-flex"
                style={{ padding: "50px" }}
              >
                <Spinner />
              </div>
            </div>
          </div>
        )}

        <div className="main-separator my-5 py-3"></div>
        {nftSupplies.length > 0 && (
          <>
            <div className="row pb-5">
              <div className="col-12">
                <div className="section-page-head">
                  <span className="bloc-logo-aoz mb-2 mb-lg-0"></span>
                  <span className="bloc-chevron-right mx-2 d-none d-lg-block"></span>
                  <span className="bloc-head-title pr-3">
                    NFT Staking rewards
                  </span>
                  <span className="bloc-head-separator"></span>
                  <span className="bloc-head-subtitle">
                    Amount of KOSON earned daily by each staked NFT. These
                    values are approximate
                  </span>
                </div>
              </div>
            </div>
            {landChestSupply.length > 0 && stakedLandChestsSupply.length > 0 ? (
              <div className="row bloc-table-row justify-content-center mb-5">
                <div className="col-12 col-lg-6 bloc-table">
                  <div className="bloc-table-content d-flex">
                    <div className="w-100">
                      <div className="bloc-table-title">Soul Allocation</div>
                      <div className="bloc-table-value">
                        {parseFloat(
                          nftStakingKosonAllocation.toFixed(2)
                        ).toLocaleString()}{" "}
                        <KossonsSymbol className="egld-sym  align-items-center" />
                      </div>
                    </div>
                    {/* <div className='w-33 px-3'>
                      <img src={`/nftSupplies/${NftSuppliesMappingImg['ORIGIN']}`} alt="" />
                    </div> */}
                  </div>
                </div>
                <div className="col-12 col-lg-6 bloc-table">
                  <div className="bloc-table-content d-flex">
                    <div className="w-100">
                      <div className="bloc-table-title">Soul</div>
                      <div className="bloc-table-value">
                        {parseFloat(
                          (
                            DAILY_SOUL_NFT_STAKING_REWARD / totalStakedNfts
                          ).toFixed(2)
                        ).toLocaleString()}{" "}
                        <KossonsSymbol className="egld-sym  align-items-center" />{" "}
                        / NFT
                      </div>
                    </div>
                    <div className="w-33 px-3">
                      <img
                        src={`/nftSupplies/${NftSuppliesMappingImg["ORIGIN"]}`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 bloc-table">
                  <div className="bloc-table-content d-flex">
                    <div className="w-100">
                      <div className="bloc-table-title">
                        Land Chest Allocation
                      </div>
                      <div className="bloc-table-value">
                        {parseFloat(
                          landChestStakingKosonAllocation.toFixed(2)
                        ).toLocaleString()}{" "}
                        <KossonsSymbol className="egld-sym  align-items-center" />
                      </div>
                    </div>
                  </div>
                </div>
                {validNFTTickersMaping
                  .filter((_, i) => i >= 14)
                  .map((lcs: any, index) => (
                    <div
                      key={index + 300}
                      className="col-12 col-lg-3 bloc-table"
                    >
                      <div className="bloc-table-content d-flex">
                        <div className="w-100">
                          <div className="bloc-table-title">
                            {LandChestNftSuppliesMappingImg[lcs].replace(
                              ".png",
                              ""
                            )}
                          </div>
                          <div className="bloc-table-value">
                            {parseFloat(
                              (
                                DAILY_LAND_CHEST_STAKING_REWARDS[lcs] /
                                getTotalStakedByTokenIdentifier(lcs)
                              ).toFixed(2)
                            ).toLocaleString()}{" "}
                            <KossonsSymbol className="egld-sym  align-items-center" />{" "}
                            / NFT
                          </div>
                        </div>
                        <div className="w-33 px-3">
                          <img
                            src={`/nftSupplies/${LandChestNftSuppliesMappingImg[lcs]}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="row bloc-table-row justify-content-center mb-5">
                <div className="col-12 col-lg-12 bloc-table">
                  <div
                    className="bloc-table-content bloc-table-value d-flex"
                    style={{ padding: "50px" }}
                  >
                    <Spinner />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className="main-separator my-5 py-3"></div>
        <div className="row pb-5">
          <div className="col-12">
            <div className="section-page-head">
              <span className="bloc-logo-koson mb-2 mb-lg-0"></span>
              <span className="bloc-chevron-right mx-2 d-none d-lg-block"></span>
              <span className="bloc-head-title pr-3">Smart Contract Stats</span>
              <span className="bloc-head-separator"></span>
              <span className="bloc-head-subtitle">
                Additional informations about the smart contracts we use
              </span>
            </div>
          </div>
        </div>
        {totalSmartContractTransactionsCount === undefined ? (
          <div className="row bloc-table-row justify-content-center mb-5">
            <div className="col-12 col-lg-12 bloc-table">
              <div
                className="bloc-table-content bloc-table-value d-flex"
                style={{ padding: "50px" }}
              >
                <Spinner />
              </div>
            </div>
          </div>
        ) : (
          <div className="row bloc-table-row">
            {smartContractStats && smartContractStats.length > 0
              ? smartContractStats?.map((scData, index) => {
                  return (
                    <>
                      <div
                        className="col-12 col-lg-6 bloc-table"
                        key={index + 400}
                      >
                        <div className="bloc-table-content">
                          <div className="bloc-table-title">
                            {scData.scDisplayName}:
                          </div>
                          <div className="bloc-table-value">
                            <a
                              href={`https://explorer.elrond.com/accounts/${scData.scAddress}`}
                            >
                              erd1...{scData.scAddress.substring(32)}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-12 col-lg-6 bloc-table"
                        key={index + 500}
                      >
                        <div className="bloc-table-content">
                          <div className="bloc-table-title">
                            Transactions so far:
                          </div>
                          <div className="bloc-table-value">
                            {scData.transactionsCount}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
            <div className="col-12 col-lg-12 bloc-table">
              <div className="bloc-table-content">
                <div className="bloc-table-title color-main1">
                  Total transactions so far:
                </div>
                <div className="bloc-table-value">
                  {(totalSmartContractTransactionsCount ?? 0).toLocaleString(
                    "en-US"
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className='row mb-5'>
          <div className='col'>
            <a className='btn btn-primary' onClick={() => console.log(nftSnapshot)}>Log Nft snapshot</a>
          </div>
          <div className='col'>
            <a className='btn btn-primary' onClick={() => console.log(kosonSnapshot)}>Log Koson snapshot</a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default KeyStats;
