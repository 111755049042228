import * as React from 'react';
import GoToTop from './../../../components/Layout/GoToTop';
import SocialLinks from './../../../components/Layout/SocialLinks';
import { jobsList } from './../jobData';
import {useNavigate} from "react-router-dom";
import { useParams } from 'react-router-dom';

const Job = (job: any, handleViewJob: any) => {
  return (
    <div className='bloc-content-element element-tops'>
      <div className="bloc-content-element-number">{job.id}</div>
      <div className="bloc-content-element-img empty rounded-full d-flex align-items-center justify-content-center" style={{ background: '#161618'}}>
        <img src="/logo-black.png" className='opacity-6' alt="No picture" style={{ width: 'auto', height: '40px' }}/>
      </div>
      <div className="bloc-content-element-content text-left" style={{ flex: 'auto' }}>
        <div className="bloc-content-element-content-head">
          <div className="bloc-content-element-content-head-title pb-2">
            {job.title}
          </div>
        </div>
        <div className="bloc-content-element-content-description">
          {job.status === 'active' &&
            <div className='d-flex align-items-center'>
              <span className="d-inline-block rounded-circle bg-success mr-2" style={{ width: "5px", height: "5px" }}></span>
              <span className='d-inline-block'>Active</span>
              <span className='d-inline-block separator mx-1'></span>
              <span className='d-inline-block'>We are waiting for you!</span>
            </div>
          }
          {job.status === 'paused' &&
            <div className='d-flex align-items-center'>
              <span className="d-inline-block rounded-circle bg-danger mr-2" style={{ width: "5px", height: "5px" }}></span>
              <span className='d-inline-block'>Paused</span>
              <span className='d-inline-block separator mx-1'></span>
              <span className='d-inline-block'>Temporarily suspended!</span>
            </div>
          }
        </div>
      </div>
      <button className={'bloc-content-element-action ' + (job.status === 'active' ? 'light' : 'disabled')} onClick={(e) => { job.status === 'active' && handleViewJob(e, job.id) }}>
        Send CV
      </button>
    </div>
  );
}

const JobPage = () => {

  const params = useParams();
  const navigate = useNavigate();
  const handleViewJob = (e: React.MouseEvent, id: number) => {
    navigate(`/jobs/${id}`);
    return;
  };
 
  function getJobDetails(jobId: any) {
    let retObj = {
      id: 0,
      title: '-',
      status: 'paused',
      createdAt: '',
      candidates: '',
      details: '',
    };
    if (Number(jobId) > 0) {
      const jobIdx = jobsList.findIndex(job => ( Number(job.id) === Number(jobId)));
      if (jobIdx !== -1) {
        return jobsList[jobIdx];
      }
    }
    return retObj;
  } 
  function ensureStr(str: any) {
    if (!str) {
      return '';
    }
    return str;
  }

  return (
    <div className='job-page mt-5 pt-5'>
      <SocialLinks />
      <GoToTop />
      <div className='job-container m-5 pt-5'>
        <div className='container px-4 px-lg-5 pt-5 d-flex flex-column h-100 align-items-center justify-content-center'>
          <div className='flex-fill w-100 pt-3 pb-5'>
            <div className='section-item flex-fill w-100 expanded'>
              <div className='w-100'>
                <h1>{getJobDetails(params?.jobId).title}</h1>
              </div>
              <div className='text-muted small'>
                <span className='font-weight-bolder'>Published at {getJobDetails(params?.jobId).createdAt}</span>
                <span className='separator mx-1'></span>
                <span className='colored font-weight-bolder'>{getJobDetails(params?.jobId).candidates}</span>
              </div>
              <div className='flex-fill w-100 pt-5 small'  dangerouslySetInnerHTML={{ __html: ensureStr(getJobDetails(params?.jobId).details) }}/>
              <div className='mt-5'>
                <a href='mailto:careers@wenmoonstudios.com' className='btz borderz light'>
                  <span className='outer'>Send CV</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='main-separator my-5 py-5'></div>
        <div className='container'>
          <div className='features-container container px-4 px-lg-5 py-5'>
            <div className='row mb-3'>
              <div className='col-12'>
                <div className='other-jobs-container w-100 py-4 mt-4 text-center justify-content-center align-items-center'>
                  <h2 className='section-title bg-rm w-100'>Others jobs</h2>
                  <div className="row bloc-content">
                    {jobsList.map(job => (
                     ((job.id !== Number(params?.jobId)) ?
                      <div className='col-12 col-lg-6 p-3' key={job.id}>
                        {Job(job, handleViewJob)}
                      </div>
                     : '')
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPage;
