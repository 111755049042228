import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { routeNames } from "routes";
// import ModalContainer from '@elrondnetwork/dapp-core/build/UI/ModalContainer/index';
import ModalContainer from "../../UI/ModalContainer/index";
import { ReactComponent as MaiarLogo } from "./../../../assets/img/maiar.svg";

const ConnectButton = ({
  logo,
  textBlack,
}: {
  logo?: string;
  textBlack?: boolean;
}) => {
  const [showConnectModal, setShowConnectModal] = useState(false);

  const handleOpenModal = () => {
    setShowConnectModal(true);
  };

  const handleCloseModal = () => {
    setShowConnectModal(false);
  };
  const conAct1 = (e: React.MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const el: any = document.querySelector(".coreConnWrapBtn1_wrapper")!;
    el.click();
  };
  const conAct2 = (e: React.MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const el: any = document.querySelector(".coreConnWrapBtn2_wrapper")!;
    el.click();
  };
  const conAct3 = (e: React.MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const el: any = document.querySelector(".coreConnWrapBtn3_wrapper")!;
    el.click();
  };
  const conAct4 = (e: React.MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const el: any = document.querySelector(".coreConnWrapBtn4_wrapper")!;
    el.click();
  };

  // if (isLoggedIn) {
  //   return (
  //     <Fragment>
  //       <Link
  //         to={routeNames.myAccount}
  //         className={
  //           'nav-link' +
  //           (window.location.pathname === routeNames.myAccount ? ' active' : '')
  //         }
  //       >
  //         {logo && logo === 'none' ? (
  //           <span>My Account</span>
  //         ) : (
  //           <div className='maiar-logo-container'>
  //             <MaiarLogo className='maiar-logo mr-2' /> My Account
  //           </div>
  //         )}
  //       </Link>
  //     </Fragment>
  //   );
  // }
  // return (
  //   <Fragment>
  //     {logo && logo === 'none' ? (
  //       <a onClick={handleOpenModal} className='nav-link' style={{color: textBlack ? 'black' : 'white'}}>
  //         Connect
  //       </a>
  //     ) : (
  //       <a onClick={handleOpenModal} className='nav-link maiar-logo-container anom'>
  //         <MaiarLogo className='maiar-logo mr-2' /> Connect
  //       </a>
  //     )}
  //     {showConnectModal && (
  //       <ModalContainer
  //         className='connect-modal'
  //         onClose={handleCloseModal}
  //         title={false}
  //       >
  //         <div className='container connect-container'>
  //           <div className='w-100'>
  //             <div className='card'>
  //               <div className='card-body'>
  //                 <h4 className='connect-title'>Connect your wallet</h4>
  //                 <div className='connect-elements'>
  //                   <div className='connect-element' onClick={conAct1}>
  //                     <div className='connect-element-image'></div>
  //                     <div className='connect-element-content'>
  //                       <div className='connect-element-title'>
  //                         Maiar De-Fi Extension
  //                       </div>
  //                       <div className='connect-element-description'>
  //                         (Chrome, Vivaldi, Edge and Brave)
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className='connect-element' onClick={conAct4}>
  //                     <div className='connect-element-image'></div>
  //                     <div className='connect-element-content'>
  //                       <div className='connect-element-title'>
  //                         Maiar Mobile App
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className='connect-element' onClick={conAct2}>
  //                     <div className='connect-element-image'></div>
  //                     <div className='connect-element-content'>
  //                       <div className='connect-element-title'>
  //                         Elrond Wallet
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className='connect-element' onClick={conAct3}>
  //                     <div className='connect-element-image'></div>
  //                     <div className='connect-element-content'>
  //                       <div className='connect-element-title'>
  //                         Ledger (Coming soon)
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <ExtensionLoginButton
  //                     callbackRoute={routeNames.home}
  //                     loginButtonText={'Extension'}
  //                     className='coreConnWrapBtn1'
  //                   />
  //                   <WebWalletLoginButton
  //                     callbackRoute={routeNames.home}
  //                     loginButtonText={'Web wallet'}
  //                     className='coreConnWrapBtn2'
  //                   />
  //                   <LedgerLoginButton
  //                     loginButtonText={'Ledger'}
  //                     callbackRoute={routeNames.home}
  //                     className='coreConnWrapBtn3'
  //                   />
  //                   <WalletConnectLoginButton
  //                     callbackRoute={routeNames.home}
  //                     loginButtonText={'to Age of Zalmoxis'}
  //                     className='coreConnWrapBtn4'
  //                   />
  //                 </div>
  //                 <div
  //                   className='tiny-secondary-link mt-3 text-center'
  //                   onClick={handleCloseModal}
  //                 >
  //                   <span>Close</span>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </ModalContainer>
  //     )}
  //   </Fragment>
  // );
  return (
    <a
      href="https://app.ageofzalmoxis.com"
      className="nav-link"
      style={{ color: textBlack ? "black" : "white" }}
      target="_blank"
      rel="noreferrer"
    >
      Dashboard
    </a>
  );
};

export default ConnectButton;
