import React, { Fragment } from "react";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import ConnectButton from "../ConnectButton/index";
import { ReactComponent as Logo } from "./../../../assets/img/logo.svg";
import SocialLinks from "./../../../components/Layout/SocialLinks";
import { getKosonPrice } from "apiRequests";
import { ReactComponent as BitmartIcon } from "./../../../assets/img/bitmart-icon.svg";

const Navbar = ({ hideAnnouncements }: { hideAnnouncements?: boolean }) => {
  const [kosonPriceTag, setKosonPriceTag] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const menuToggle = (e: React.MouseEvent) => {
    const target = e.target as Element;
    if (target && target.parentNode && target.parentNode.parentNode) {
      const parentNode = target.parentNode.parentNode.parentNode as Element;
      if (parentNode.className.indexOf("mainMobileNav") !== -1) {
        if (parentNode.className.indexOf("open") !== -1) {
          parentNode.className = "navbar mainMobileNav";
        } else {
          parentNode.className = "navbar mainMobileNav open";
        }
      }
    }
  };

  React.useEffect(() => {
    const mainMobileNav = document.querySelector(".mainMobileNav") as Element;
    mainMobileNav.className = "navbar mainMobileNav";
  }, [location]);

  React.useEffect(() => {
    getKosonPrice().then((res) => {
      setKosonPriceTag(res.data);
    });
  }, []);

  // const BitmartIcon = () => {
  //   return <BitmartIcon style={{display: 'block', width: '20px', height: '20px'}} />
  // }

  return (
    <Fragment>
      {/* {!hideAnnouncements && <div className='w-100 d-flex text-center align-content-center flex-column'>
        {isLoggedIn && <p style={{ fontSize: '14px', padding: '10px', marginBottom: '0px', color: '#dcc7a6', zIndex: '1000000', backgroundColor: 'black' }}>$KOSON Staking is live! <a href={`${routeNames.myAccount}`}>Take me to my account!</a></p>}
        {!isLoggedIn && <p style={{ fontSize: '14px', padding: '10px', marginBottom: '0px', color: '#dcc7a6', zIndex: '1000000', backgroundColor: 'black' }}>$KOSON Staking is live! Connect now to stake $KOSON and earn up to 138.46% APY and a share of the $1M prize!</p>}
      </div>} */}
      <div className="w-100 d-flex text-center align-content-center flex-column">
        <div
          className="container-fluid"
          style={{ zIndex: "2", width: "100%", backgroundColor: "black" }}
        >
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <p
                style={{
                  fontSize: "14px",
                  padding: "10px",
                  marginBottom: "0px",
                  color: "#dcc7a6",
                  backgroundColor: "black",
                }}
              >
                {/* $KOSON Staking is live! Connect now to stake $KOSON and earn
                  up to 138.46% APY and a share of the $1M prize! */}
                The new dashboard version is now live{" "}
                <a href="https://app.ageofzalmoxis.com" target="_blank">
                  here
                </a>
                .
              </p>
            </div>
            <div className="col-lg-3 d-flex justify-content-end">
              <a
                href="https://www.bitmart.com/trade/en-US?symbol=KOSON_USDT"
                target="_blank"
                style={{
                  fontSize: "14px",
                  padding: "10px",
                  marginBottom: "0px",
                  color: "#dcc7a6",
                  backgroundColor: "black",
                }}
              >
                <BitmartIcon
                  style={{ display: "inline", width: "20px", height: "20px" }}
                />{" "}
                KOSON Price: {kosonPriceTag}$
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar mainMobileNav">
        <div className="container p-4">
          <div className="w-100 d-flex align-items-center justify-content-between text-left">
            <div className="w-25 mr-0 text-left menu-btn" onClick={menuToggle}>
              Menu
            </div>
            <Link to={routeNames.home} className={"nav-link text-left"}>
              <Logo className="zalmoxis-logo zalmoxis-logo-mobile-nav" />
            </Link>
            <div className="w-25 mr-0 text-left">
              <SocialLinks />
            </div>
          </div>
          <div className="menu-items">
            <ConnectButton logo={"none"} />
            <NavItem>
              <Link
                to={routeNames.home}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.home ? " active" : "")
                }
              >
                Homepage
              </Link>
            </NavItem>
            <NavItem>
              <a
                href="https://blog.ageofzalmoxis.com/"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Blog
              </a>
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.bridge}
                rel="noreferrer"
                className={"nav-link"}
              >
                Bridge
              </Link>
            </NavItem>
            <NavItem>
              <a
                href="https://whitepaper.ageofzalmoxis.com/roadmap"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Roadmap
              </a>
            </NavItem>
            <NavItem>
              {/* <div className='nav-link nav-link-disabled'>
                Partner (coming soon)
              </div> */}
              <Link
                to={routeNames.keyStats}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.keyStats ? " active" : "")
                }
              >
                Stats
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.merchandise}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.merchandise
                    ? " active"
                    : "")
                }
              >
                Merch
              </Link>
            </NavItem>
            <NavItem>
              {/* <Link
                to={routeNames.marketplace}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.marketplace
                    ? " active"
                    : "")
                }
              >
                Marketplace
              </Link> */}
              <a
                href="https://app.ageofzalmoxis.com/marketplace"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Marketplace
              </a>
              {/* <div className='nav-link nav-link-disabled'>
                Marketplace (coming soon)
              </div> */}
            </NavItem>
            <NavItem>
              <a
                href="https://whitepaper.ageofzalmoxis.com/"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Whitepaper
              </a>
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.team}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.team ? " active" : "")
                }
              >
                Team
              </Link>
            </NavItem>
          </div>
        </div>
      </div>
      <BsNavbar className="mainNav navbar navbar-expand-lg">
        <div className="container px-2">
          <Nav className="navbar-nav ms-auto w-100 align-items-center justify-content-between">
            <NavItem>
              <Link
                to={routeNames.home}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.home ? " active" : "")
                }
              >
                Homepage
              </Link>
            </NavItem>
            <NavItem>
              <a
                href="https://blog.ageofzalmoxis.com/"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Blog
              </a>
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.bridge}
                rel="noreferrer"
                className={"nav-link"}
              >
                Bridge
              </Link>
            </NavItem>
            <NavItem>
              <a
                href="https://whitepaper.ageofzalmoxis.com/roadmap"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Roadmap
              </a>
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.keyStats}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.keyStats ? " active" : "")
                }
              >
                Stats
              </Link>
              {/* <div className='nav-link nav-link-disabled'>
                Partners
                <div className='nav-tooltip'>Coming soon</div>
              </div> */}
            </NavItem>
            <NavItem
              className="nav-item-custom"
              onClick={() => {
                navigate("/");
              }}
            >
              <Logo className="zalmoxis-logo" />
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.merchandise}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.merchandise
                    ? " active"
                    : "")
                }
              >
                Merch
              </Link>
            </NavItem>
            <NavItem>
              {/* <Link
                to={routeNames.marketplace}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.marketplace
                    ? " active"
                    : "")
                }
              >
                Marketplace
              </Link> */}
              <a
                href="https://app.ageofzalmoxis.com/marketplace"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Marketplace
              </a>
              {/* <div className='nav-link nav-link-disabled'>
                Marketplace
                <div className='nav-tooltip'>Coming soon</div>
              </div> */}
            </NavItem>
            <NavItem>
              <a
                href="https://whitepaper.ageofzalmoxis.com/"
                target="_blank"
                rel="noreferrer"
                className={"nav-link"}
              >
                Whitepaper
              </a>
            </NavItem>
            <NavItem>
              <Link
                to={routeNames.team}
                className={
                  "nav-link" +
                  (location.pathname === routeNames.team ? " active" : "")
                }
              >
                Team
              </Link>
            </NavItem>
            <NavItem>
              <ConnectButton />
            </NavItem>
          </Nav>
        </div>
      </BsNavbar>
    </Fragment>
  );
};

export default Navbar;
