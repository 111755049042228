import React, { Component } from 'react';
type Props = any;
type State = { height: number; isBottom: boolean };
export default class GoToTop extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      height: window.innerHeight,
      isBottom: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight - 300) {
      this.setState({
        isBottom: true
      });
    } else {
      this.setState({
        isBottom: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  render() {
    const isBottom = this.state.isBottom;
    return (
      <div
        className={'gototop-fixed' + (isBottom ? ' in-bottom' : '')}
        onClick={this.scrollToTop}
      ></div>
    );
  }
}
