import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Tos from "./pages/Tos";
import Team from "pages/Team";
import Jobs from "pages/Team/Jobs";
import KeyStats from "pages/KeyStats";
import Bridge from "pages/Bridge";
import RiskDisclosure from "pages/RiskDisclosure";
import Merchandise from "pages/Merchandise";

export const routeNames = {
  home: "/",
  dashboard: "/dashboard",
  unlock: "/unlock",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
  // adminDashboard: "/adminDashboard",
  // marketplaceListings: "/marketplace-listings",
  // marketplace: "/marketplace",
  // createOffer: "/marketplace/:offerId/create",
  // viewOffer: "/marketplace/:offerId",
  // myAccount: "/my-account",
  // nftDetails: "/my-account/:tokenId",
  team: "/team",
  jobs: "/jobs/:jobId",
  // land: "/land",
  tos: "/Tos",
  privacy: "/privacy-policy",
  // nfts: "/unlisted-nfts",
  // nftPage: "/nft/:nftIdentifier",
  keyStats: "/stats",
  bridge: "/bridge",
  riskDisclosure: "/risk-disclosure",
  merchandise: "/merchandise",
};

export const routes: Array<any> = [
  {
    path: routeNames.home,
    title: "Home",
    component: Home,
  },
  // {
  //   path: routeNames.marketplaceListings,
  //   title: "Marketplace Listings",
  //   component: MarketplaceListings,
  // },
  // {
  //   path: routeNames.marketplace,
  //   title: "Marketplace",
  //   component: Marketplace,
  // },
  // {
  //   path: routeNames.createOffer,
  //   title: "Create Offer",
  //   component: CreateOffer,
  // },
  // {
  //   path: routeNames.viewOffer,
  //   title: "View Offer",
  //   component: ViewOffer,
  // },
  // {
  //   path: routeNames.myAccount,
  //   title: "My Account",
  //   component: MyAccount,
  // },
  // {
  //   path: routeNames.land,
  //   title: "Land",
  //   component: Land,
  // },
  {
    path: routeNames.team,
    title: "Team",
    component: Team,
  },
  {
    path: routeNames.jobs,
    title: "Jobs",
    component: Jobs,
  },
  {
    path: routeNames.tos,
    title: "Terms of Service",
    component: Tos,
  },
  {
    path: routeNames.privacy,
    title: "Privacy Policy",
    component: Privacy,
  },
  // {
  //   path: routeNames.nfts,
  //   title: "Unlisted NFTs",
  //   component: UnlistedNFTs,
  // },
  // {
  //   path: routeNames.nftPage,
  //   title: "Unlisted NFTs",
  //   component: NFTPage,
  // },
  {
    path: routeNames.keyStats,
    title: "Key stats",
    component: KeyStats,
  },
  {
    path: routeNames.bridge,
    title: "Bridge",
    component: Bridge,
  },
  {
    path: routeNames.riskDisclosure,
    title: "Risk Disclosure",
    component: RiskDisclosure,
  },
  {
    path: routeNames.merchandise,
    title: "Merchandise",
    component: Merchandise,
  },
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
