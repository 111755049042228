import * as React from 'react';
import GoToTop from './../../components/Layout/GoToTop';
import SocialLinks from './../../components/Layout/SocialLinks';

const Privacy = () => {
  return (
    <div>
      <SocialLinks />
      <GoToTop />
      <div className='privacy-container m-5'>
        <div className='container px-4 px-lg-5 pt-5 d-flex flex-column h-100 align-items-center justify-content-center'>
          <div className='flex-fill w-100 pt-3 pb-5'>
            <div className='section-item flex-fill w-100 expanded'>
              <div className='section-page-head flex-fill w-100'>
                <h1>Privacy Policy</h1>
              </div>
              <div className='flex-fill w-100 pt-5'>
                <div>
                  Effective date: 14 March 2022
                  <br />
                  <br />
                  Wenmoon Studios Ltd. and its subsidiaries and affiliates
                  (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates
                  the &nbsp;
                  <a
                    href='https://www.ageofzalmoxis.com/privacy-policy'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://www.ageofzalmoxis.com/privacy-policy
                  </a>
                  &nbsp; website as well as the following websites, games and
                  development tools for such (hereinafter referred to as the
                  &quot;Service&quot;):
                  <br />
                  <a
                    href='https://www.ageofzalmoxis.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://www.ageofzalmoxis.com
                  </a>
                  <br />
                  <a
                    href='https://marketplace.ageofzalmoxis.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://marketplace.ageofzalmoxis.com/
                  </a>
                  <br />
                  and Services for the following “live” games: Age of Zalmoxis
                  <br />
                  <br />
                  This page informs you of our policies regarding the
                  collection, use and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data. We use your data to provide and improve the Service. By
                  using the Service, you agree to the collection and use of
                  information in accordance with this policy.
                  <br />
                  <br />
                </div>
                <br />
                <h2>DEFINITIONS</h2>
                <br />
                <br />
                <h2>Service</h2> <br />
                <div>
                  <br />
                  Service is the website, game(s) and tools offered and operated
                  by Wenmoon Studios Ltd. and the “live games” as listed above.
                  <br />
                  <br />
                </div>

                <h2>Personal Data</h2>
                <div>
                  <br />
                  <br />
                Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                  <br />
                  <br />
                </div>
                <h2>Usage Data</h2>
                <div>
                  <br />
                  Usage Data is data collected automatically either generated by
                  the use of the Service or from the Service infrastructure
                  itself (for example, the duration of a page visit).
                  <br />
                  <br />
                </div>
                <h2>Cookies</h2>
                <div>
                  <br />
                  Cookies are small files stored on your device (computer or
                  mobile device).
                  <br />
                  <br />
                </div>
                <h2>Data Controller</h2>
                <div>
                  <br />
                  Data Controller means the natural or legal person who (either
                  alone or jointly or in common with other persons) determines
                  the purposes for which and the manner in which any personal
                  information are, or are to be, processed. For the purpose of
                  this Privacy Policy, we are a Data Controller of your Personal
                  Data.
                  <br />
                  <br />
                </div>
                <h2>Data Processors (or Service Providers).</h2>
                <div>
                  <br />
                  Data Subject is any living individual who is using our Service
                  and is the subject of Personal Data.
                  <br />
                  INFORMATION COLLECTION AND USE. We collect several different
                  types of information for various purposes to provide and
                  improve our Service to you.
                  <br />
                  TYPES OF DATA COLLECTED:
                  <br />
                  <br />
                </div>
                <h2>COLLECTED DATA</h2>
                <div>
                  <br />
                  We are currently under no obligation whatsoever to collect any specific data, but as the legislative landscape will evolve and develop, we may be obligated to collect, in the future, further information from you, under the KYC procedure.
                  <br />
                  <br />
                  So, upon availing our services, in the future we may ask for your full name and/or a valid e-mail address, which may be included in our mailing list (if you elect), for future maintenance, updates, and eventual marketing promotions, a herotag, or a wallet address.
                  <br />
                  <br />
                  We may process data about your use of our website and app, or the User’s website ("usage data"). The usage data may include your IP address, geographical location, browser type, and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analyzing the use of the website and services.
                  <br />
                  We may process your personal data that are provided in the course of the use of our services ("service data"). The service data may be processed for the purposes of providing our services, ensuring the security of our website and app, maintaining back-ups of our databases and communicating with you.
                  <br />
                  <br />
                  We may process information contained in any inquiry you submit to us regarding our services and/or the app ("inquiry data"). The inquiry data may be processed for the purposes of providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you.
                  <br />
                  <br />
                  We may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you.
                  <br />
                  <br />
                  We may process information contained in or relating to any communication that you issue through the app. We warn you about the supply or display of private email addresses, or email addresses that do not belong to you, and you hereby accept that we may take any measures deemed necessary on any misuse of personal details.
                  <br />
                  <br />
                  “Automatically Collected" Information
                  <br />
                  <br />
                  The website collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files, and it is collected regardless of your quality: Website Visitor or User.
                  <br />
                  <br />
                  Collected may be:
                  <br />
                  <ul>
                  <li>the ISP</li>
                  <li>the operating system used by the accessing system</li>
                  <li>the website from which an accessing system reaches our website (so-called referrers)</li>
                  <li>the sub-website</li>
                  <li>the date and time of access to the website</li>
                  <li>an Internet Protocol address (IP address)</li>
                  <li>screen Resolution</li>
                  <li>locale Preferences</li>
                  <li>web page visited before you came to our website</li>
                  <li>information you search for on our website</li>
                  <li>date and time stamps associated with transactions</li>
                  <li>system configuration information and other interactions with the website.</li>
                  <li>social networking information (if we are provided with access to your account on social network connection services);</li>
                  <li>any other similar data and information that may be used in the event of attacks on our information technology systems.</li>
                  </ul>
                  <br />
                  <br />
                  When using these general data and information, we do not draw any conclusions about the data subject. Rather, this information is needed to:
                  <br />
                  <br />
                  <ul>
                  <li>deliver the content of our website correctly;</li>
                  <li>optimize the content of our website as well as its advertisement;</li>
                  <li>ensure the long-term viability of our information technology systems and website technology;</li>
                  <li>provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack;</li>
                  </ul>
                  <br />
                  <br />
                  In addition to the specific purposes for which we may process your personal data set out in this Section, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.
                  <br />
                  Do not supply any other person's personal data to us.
                  <br />
                  <br />
                </div>
                <h2>USAGE DATA</h2>
                <div>
                  <br />
                  We may also collect information on how the Service is accessed
                  and used (&quot;Usage Data&quot;). This Usage Data may include
                  information such as your computer&apos;s Internet Protocol
                  address (e.g. IP address), browser type, browser version, the
                  pages of our Service that you visit, the time and date of your
                  visit, the time spent on those pages, unique device
                  identifiers and other diagnostic data.
                  <br />
                  <br />
                </div>
                <h2>TRACKING COOKIES DATA</h2>
                <div>
                  <br />
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and we hold certain information.
                  Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your device. Other
                  tracking technologies are also used such as beacons, tags and
                  scripts to collect and track information and to improve and
                  analyse our Service. You can instruct your browser to refuse
                  all cookies or to indicate when a cookie is being sent.
                  However, if you do not accept cookies, you may not be able to
                  use some portions of our Service.
                  <br />
                  <br />
                </div>
                <h2>Examples of Cookies we use:</h2>
                <div>
                  <br />
                  Session Cookies. We use Session Cookies to operate our
                  Service. Preference Cookies. We use Preference Cookies to
                  remember your preferences and various settings. Security
                  Cookies. We use Security Cookies for security purposes.
                  <br />
                  <br />
                </div>
                <h2>USE OF DATA</h2>
                <div>
                  <br />
                  Wenmoon Studios Ltd. and its Data Processors uses the
                  collected data for various purposes:
                  <br />
                  To provide and maintain our Service
                  <br />
                  To notify you about changes to our Service
                  <br />
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                  <br />
                  To provide customer support
                  <br />
                  To gather analysis or valuable information so that we can
                  improve our Service
                  <br />
                  To monitor the usage of our Service
                  <br />
                  To detect, prevent and address technical issues
                  <br />
                  To provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about if you have opted to receive such
                  information
                  <br />
                  <br />
                </div>
                <h2>RETENTION OF DATA</h2>
                <div>
                  <br />
                  Wenmoon Studios Ltd. will retain your Personal Data only for
                  as long as is necessary for the purposes set out in this
                  Privacy Policy. We will retain and use your Personal Data to
                  the extent necessary to comply with our legal obligations (for
                  example, if we are required to retain your data to comply with
                  applicable laws), resolve disputes and enforce our legal
                  agreements and policies. Wenmoon Studios Ltd. will also retain
                  Usage Data for internal analysis purposes. Usage Data is
                  generally retained for a shorter period of time, except when
                  this data is used to strengthen the security or to improve the
                  functionality of our Service, or we are legally obligated to
                  retain this data for longer periods.
                  <br />
                  <br />
                </div>
                <h2>TRANSFER OF DATA</h2>
                <div>
                  <br />
                  Your information, including Personal Data, may be transferred
                  to and maintained on computers located outside of your state,
                  province, country or other governmental jurisdiction where the
                  data protection laws may differ from those of your
                  jurisdiction. Your consent to this Privacy Policy followed by
                  your submission of such information represents your agreement
                  to that transfer.
                  <br />
                  <br />
                </div>
                <h2>ONWARD TRANSFER LIABILITIES</h2>
                <div>
                  <br />
                  Wenmoon Studios Ltd. is responsible for the processing of
                  personal data it receives under the Privacy Shield Framework
                  and subsequently transfers to a third party acting as an agent
                  on its behalf. We comply with the Privacy Shield Principles
                  for all onward transfers of personal data from the EU. Wenmoon
                  Studios Ltd. complies with the EU – US Privacy Shield
                  Framework and the Swiss – US Privacy Shield Framework as set
                  forth by the U.S. Department of Commerce regarding the
                  collection, use, and retention of personal information
                  transferred from the European Union and United Kingdom, and
                  Switzerland to the United States, respectively. We have
                  certified to the Department of Commerce that we adhere to the
                  Privacy Shield Principles. If there is any conflict between
                  the terms in our privacy policy and the Privacy Shield
                  Principles, the Privacy Shield Principles shall govern. To
                  learn more about the Privacy Shield program, and to view our
                  certification, please visit https://www.privacyshield.gov/
                  <br />
                  <br />
                </div>
                <h2>
                  YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION
                  REGULATION (GDPR)
                </h2>
                <div>
                  <br />
                  If you are a resident of the European Economic Area (EEA), you
                  have certain data protection rights. Wenmoon Studios Ltd. aims
                  to take reasonable steps to allow you to correct, amend,
                  delete or limit the use of your Personal Data.
                  <br />
                  If you wish to be informed about what Personal Data we hold
                  about you and if you want it to be removed from our systems,
                  please contact us at privacy@wenmoonstudios.com
                  <br />
                  The Data Controller is Wenmoon Studios Ltd. The Data
                  controller may be contacted at: privacy@wenmoonstudios.com .
                  <br />
                  In certain circumstances, you have the following data
                  protection rights:
                  <br />
                  The right to access, update or delete the information we have
                  on you. Whenever made possible, you can access, update or
                  request deletion of your Personal Data directly within your
                  account settings section. If you are unable to perform these
                  actions yourself, please contact us to assist you. The right
                  of rectification. You have the right to have your information
                  rectified if that information is inaccurate or incomplete. The
                  right to object. You have the right to object to our
                  processing of your Personal Data.
                  <br />
                  The right of restriction. You have the right to request that
                  we restrict the processing of your personal information.
                  <br />
                  The right to data portability. You have the right to be
                  provided with a copy of the information we have on you in a
                  structured, machine-readable and commonly used format.
                  <br />
                  The right to withdraw consent. You also have the right to
                  withdraw your consent at any time where Wenmoon Studios Ltd.
                  relied on your consent to process your personal information.
                  <br />
                  Please note that we may ask you to verify your identity before
                  responding to such requests. You have the right to complain to
                  a Data Protection Authority about our collection and use of
                  your Personal Data. For more information, please contact your
                  local data protection authority in the European Economic Area
                  (EEA).
                  <br />
                  <br />
                </div>
                <h2>SERVICE PROVIDERS</h2>
                <div>
                  <br />
                  We may employ third party companies and individuals to
                  facilitate our Service (&quot;Service Providers&quot;),
                  provide the Service on our behalf, perform Service-related
                  services or assist us in analyzing how our Service is used.
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose. Professional
                  advisers, including lawyers, bankers, auditors and insurers
                  who provide consultancy, banking, legal, insurance and
                  accounting services. If Wenmoon Studios Ltd. or substantially
                  all of its assets are acquired by a third party, in which case
                  personal data held by it about its customers will be one of
                  the transferred assets.
                  <br />
                  Disclosure for Law Enforcement. Under certain circumstances,
                  Wenmoon Studios Ltd. may be required to disclose your Personal
                  Data if required to do so by law or in response to valid
                  requests by public authorities (e.g. a court or a government
                  agency).
                  <br />
                  Legal Requirements Wenmoon Studios Ltd. may disclose your
                  Personal Data in the good faith belief that such action is
                  necessary to:
                  <br />
                  To comply with a legal obligation
                  <br />
                  To protect and defend the rights or property of Wenmoon
                  Studios Ltd.
                  <br />
                  To prevent or investigate possible wrongdoing in connection
                  with the Service
                  <br />
                  To protect the personal safety of users of the Service or the
                  public
                  <br />
                  To protect against legal liability
                  <br />
                  We may provide paid products and/or services within the
                  Service. In that case, we use third-party services for payment
                  processing (e.g. payment processors).
                  <br />
                  <br />
                </div>
                <h2>SECURITY OF DATA</h2>
                <div>
                  <br />
                  The security of your data is important to us but remember that
                  no method of transmission over the Internet or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                  <br />
                  We require all third parties to respect the security of your
                  personal data and to treat it in accordance with the law. We
                  do not allow our third-party service providers to use your
                  personal data for their own purposes and only permit them to
                  process your personal data for specified purposes and in
                  accordance with our instructions.
                  <br />
                  <br />
                </div>
                <h2>LINKS TO OTHER SITES</h2>
                <div>
                  <br />
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third party link, you will be
                  directed to that third party&apos;s site. We strongly advise
                  you to review the Privacy Policy of every site you visit. We
                  have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                  <br />
                  <br />
                </div>
                <h2>CHILDREN&apos;S PRIVACY</h2>
                <div>
                  <br />
                  Our Service does not address anyone under the age of 18
                  (&quot;Children&quot;).
                  <br />
                  We do not knowingly collect personally identifiable
                  information from anyone under the age of 18. If you are a
                  parent or guardian and you are aware that your Child has
                  provided us with Personal Data, please contact us. If we
                  become aware that we have collected Personal Data from
                  children without verification of parental consent, we take
                  steps to remove that information from our servers.
                  <br />
                  <br />
                </div>
                <h2>CHANGES TO THIS PRIVACY POLICY</h2>
                <div>
                  <br />
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                  <br />
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  the &quot;effective date&quot; at the top of this Privacy
                  Policy.
                  <br />
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                  <br />
                  <br />
                </div>
                <h2>OVERSIGHT BY FEDERAL TRADE COMMISSION</h2>
                <div>
                  <br />
                  We are subject to the investigatory and enforcement powers of
                  the U.S. Federal Trade Commission with respect to personal
                  data received or transferred pursuant to the EU-U.S. Privacy
                  Shield Framework including the onward transfer liability
                  provisions. In certain situations, we may be required to
                  disclose personal data in response to lawful requests by
                  public authorities, including to meet national security or law
                  enforcement requirements.
                  <br />
                  <br />
                </div>
                <h2>CONTACT AND DISPUTE RESOLUTION</h2>
                <div>
                  <br />
                  If you would like to place a request to modify or request a
                  copy of your personal information in accordance with GDPR or
                  other Privacy regulations or laws ; send requests to
                  privacy@wenmoonstudios.com. In compliance with the Privacy
                  Shield Principles, Wenmoon Studios Ltd. commits to resolve
                  complaints about our collection or use of your personal
                  information. EU and Swiss individuals with inquiries or
                  complaints regarding our Privacy Shield policy should first
                  contact us at: privacy@wenmoonstudios.com or by physical mail
                  to: Wenmoon Studios Limited, Coliemore House, Coliemore Road,
                  Dalkey, Co. Dublin, A96 A8D5, Ireland
                  <br />
                  <br />
                  Wenmoon Studios Ltd. has further committed to refer unresolved
                  Privacy Shield complaints to JAMS Privacy Shield Program , an
                  alternative dispute resolution provider located in the United
                  States. If you do not receive timely acknowledgment of your
                  complaint from us, or if we have not addressed your complaint
                  to your satisfaction, please visit
                  https://www.jamsadr.com/eu-us-privacy-shield for more
                  information or to file a complaint. The services of JAMS are
                  provided at no cost to you. Under certain conditions more
                  fully described on the Privacy Shield website, you may be
                  entitled to invoke binding arbitration through the Privacy
                  Shield Panel when other dispute resolution procedures have
                  been exhausted.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
