import * as React from 'react';
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as ElrondSymbol } from './../../assets/img/elrond-symbol.svg';
import { ReactComponent as Info } from './../../assets/img/info.svg';
import { ReactComponent as ButtonsMacWindows } from './../../assets/img/windows-mac-os.svg';
import GoToTop from './../../components/Layout/GoToTop';
import SocialLinks from './../../components/Layout/SocialLinks';

const Home = () => {
  const faqToggle = (e: React.MouseEvent) => {
    const target = e.target as Element;
    if (target && target.parentNode) {
      const parentNode = target.parentNode as Element;
      if (
        parentNode.className === 'faq-question' ||
        parentNode.className === 'faq-question expanded'
      ) {
        if (parentNode.className.indexOf('expanded') !== -1) {
          parentNode.className = 'faq-question';
        } else {
          parentNode.className = 'faq-question expanded';
        }
      } else {
        const parentNode = target.parentNode.parentNode as Element;
        if (parentNode.className.indexOf('expanded') !== -1) {
          parentNode.className = 'faq-question';
        } else {
          parentNode.className = 'faq-question expanded';
        }
      }
    }
  };
  const openNews = (e: React.MouseEvent, link: string) => {
    const w = window.open(link, '_blank');
    if (w) {
      w.focus();
    }
  };
  const smoothScroll = (e: React.MouseEvent, selector: string) => {
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  const calcWidth = () => {
    let date = new Date().getTime();

    return '0%';
  }

  return (
    <div>
      <SocialLinks />
      <GoToTop />
      <div className='masthead-with-rm'>
        <header className='masthead'>
          <div className='container px-4 px-lg-5 d-flex flex-column'>
            <div className='container-main-ftlogo-block d-flex flex-row align-items-center justify-content-center'>
              <div className='container-main-ftlogo-text d-flex flex-fill flex-grow-1 align-items-center justify-content-center w-50'>
                <div className='text-resp'>
                  <img className='logo-aoz-fulltext' src='logoaoz.png' />
                  <h2 className='text-s1-1'>
                    An ancient fantasy play-to-earn MMORPG
                  </h2>
                  <p className='text-s1 color-main7 shadow'>
                    Take the role of a proud Dacian champion, whose body merges
                    with a heroic soul. You will fight otherworldly monsters and
                    mortal foes who seek to further fragment the Dacian kingdom.
                    <br />
                    Join an incredible experience and profit from the blockchain
                    &amp; NFT integration.
                  </p>
                  <div className='d-flex flex-fill flex-grow-1 masthead-btn'>
                    <div className='btz borderz mt-3'>
                      <a
                        href='https://app.ageofzalmoxis.com/marketplace'
                        rel='noreferrer'
                        target='_blank'
                      >
                        <span className='outer'>Buy a Hero Soul</span>
                      </a>
                    </div>
                    <div className='btz borderz mt-3'>
                      {/* <a
                        href='https://whitepaper.ageofzalmoxis.com/gameplay/territory'
                        rel='noreferrer'
                        target='_blank'
                      >
                        <span className='outer'>Land Sale (TBA)</span>
                      </a> */}
                      <a
                        // href='/land'
                        href='https://app.ageofzalmoxis.com/land-sale'
                        rel='noreferrer'
                        target='_blank'
                      >
                        <span className='outer'>Land Sale</span>
                      </a>
                    </div>
                  </div>
                  {/* <ButtonsMacWindows className='ml-3 d-inline' /> */}
                </div>
              </div>
              <div className='container-main-ftlogo-rpad w-50'>&nbsp;</div>
            </div>
            <div className='overlap-content d-flex flex-wrap align-items-center justify-content-center flex-fill flex-grow-1 card-wrapper'>
              <div className='overlap-content-g order-1'>
                {/*<div className='card-trans card-body card rounded border-0 p-0 mx-lg-4'>*/}
                {/* <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/XjrE2NJWX_E?controls=0'
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; clipboard-write; encrypted-media; gyroscope;'
                  allowFullScreen
                ></iframe> */}
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/NptOkCq2q8g?controls=0'
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
                {/*</div>*/}
              </div>
              <div className='overlap-content-d order-2'>
                <div className='card-body w-100 h-100 card rounded border-0 py-4 px-5'>
                  <ul className='nav nav-tabs align-items-end justify-content-end'>
                    <li className='nav-item nav-item-head mr-auto'>
                      <div
                        className='nav-link disabled pb-3'
                        aria-disabled='true'
                      >
                        Latest News
                      </div>
                    </li>
                    {/* <li className='nav-item'>
                      <a className='nav-link active pb-4' aria-current='page'>
                        All
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link pb-4'>Twitter</a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link pb-4'>Medium</a>
                    </li> */}
                  </ul>
                  <div className='listz-container d-flex flex-fill flex-grow-1 w-100 pt-3'>
                    <ul className='listz'>
                    <li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://x.com/0xMantleEco/status/1699784540041179395?s=20'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                            Mantle welcomes Age of Zalmoxis in their ecosystem
                          </div>
                          <div className='itemz-content-authoring'>
                            09.07.2023 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li>
                      <li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://x.com/Immutable/status/1692205432964825587?s=20'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                          Age Of Zalmoxis embraces Immutable zkEVM, driven by Polygon Labs
                          </div>
                          <div className='itemz-content-authoring'>
                            08.17.2023 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li>
                      <li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://coinmarketcap.com/community/profile/ageofzalmoxis/'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                          Follow us on CMC Community
                          </div>
                          <div className='itemz-content-authoring'>
                            09.05.2023 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li>
                    {/*<li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://support.mexc.com/hc/en-001/articles/10752094225561-MEXC-Will-Launch-the-7th-Session-of-Launchpad-with-Age-of-Zalmoxis-KOSON-'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                            Launchpad IEO of $KOSON token on MEXC Exchange
                          </div>
                          <div className='itemz-content-authoring'>
                            09.23.2022 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li>
                      <li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://support.bitmart.com/hc/en-us/articles/9025778800027'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                            Launchpad IEO of $KOSON token on Bitmart Exchange
                          </div>
                          <div className='itemz-content-authoring'>
                            09.23.2022 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li>
                      <li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://community.morningstar.ventures/blog/new-investments/morningstar-ventures-private-funding-age-of-zalmoxis/'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                            Morningstar Ventures Leads Private Funding Round..
                          </div>
                          <div className='itemz-content-authoring'>
                            09.22.2022 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li>
                      { <li
                        className='itemz d-flex'
                        onClick={(e) => {
                          openNews(
                            e,
                            'https://blog.ageofzalmoxis.com/isengard-dao-partners-with-wenmoon-studios-on-project-age-of-zalmoxis-7e4585813ec9'
                          );
                        }}
                      >
                        <div className='itemz-img'></div>
                        <div className='itemz-content'>
                          <div className='itemz-content-title'>
                            Isengard DAO partners with Wenmoon Studios..
                          </div>
                          <div className='itemz-content-authoring'>
                            01.22.2022 By @AgeOfZalmoxis
                          </div>
                        </div>
                        <div className='itemz-action'>+</div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className='rm-container'>
          <div className='container px-4 px-lg-5 pt-5 d-flex flex-column h-100 align-items-center justify-content-center'>
            <div className='d-flex flex-column justify-content-center w-100'>
              <div className='w-100 py-4 mt-4 text-center justify-content-center align-items-center'>
                <h2 className='section-title bg-rm w-100'>Roadmap</h2>
              </div>
              <div className='rm-block d-flex flex-row flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-between align-items-center'>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q3 21
                    <div className='rm-tooltip'>
                      <div className='rm-tooltip-title'>Q3 2021</div>
                      <div className='rm-tooltip-description'>
                        Concept and Idea
                      </div>
                    </div>
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q4 21
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q4 2021</div>
                    <div className='rm-tooltip-description'>
                      Wenmoon Studios Ltd. is incorporated and Development
                      started; $KOSON Seed Phase 1; Origin Souls Presale begins
                    </div>
                  </div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q1 22
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q1 2022</div>
                    <div className='rm-tooltip-description'>
                      $KOSON Private Sale 2, Web-version
                      Marketplace
                    </div>
                  </div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q2 22
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q2 2022</div>
                    <div className='rm-tooltip-description'>
                      NFT Staking; Summoning Feature; Land Chest Sale; Combat Milestone I: Basics (MVP)
                    </div>
                  </div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q3 22
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q3 2022</div>
                    <div className='rm-tooltip-description'>
                      $KOSON Private Sale; $KOSON Staking; $KOSON Public Sale(IEO); Combat Milestone II (Internal)
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q4 22
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q4 2022</div>
                    <div className='rm-tooltip-description'>
                      Land Chest Opening (Interactive map)
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q1 23
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q1 2023</div>
                    <div className='rm-tooltip-description'>
                      In-Game Marketplace addon for Land/items; Horse breeding
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q2 23
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q2 2023</div>
                    <div className='rm-tooltip-description'>
                      Character Customisation
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q3 23
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q3 2023</div>
                    <div className='rm-tooltip-description'>
                      Combat Milestone III: PvP Tournaments begin; Ancient Mini-Games
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q4 23
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q4 2024</div>
                    <div className='rm-tooltip-description'>
                      "Getae" Chain Alpha; Wenmoon Launcher App
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
                <div className='rm-text-block d-flex flex-column flex-fill flex-grow-1 w-100 mt-4 text-center justify-content-center align-items-center'>
                  <div className='rm-text d-flex flex-row text-center justify-content-center align-items-center'>
                    Q1 24
                    <div className='rm-info'>
                      <Info />
                    </div>
                  </div>
                  <div className='rm-tooltip'>
                    <div className='rm-tooltip-title'>Q1 2024</div>
                    <div className='rm-tooltip-description'>
                      Community Alpha Game Release
                    </div>
                  </div>
                  <div className='rm-dot'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-fill flex-grow-1 w-100 py-4 mt-4 text-center justify-content-center align-items-center'>
                <div className='progress-rm w-100'>
                  <div className='progress-bar' role='progressbar' style={{ width: '80%' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id='presale'
        className='presale-container d-flex flex-wrap flex-column w-100'
      >
        <div className='container px-4 px-lg-5 d-flex flex-column h-100 align-items-center justify-content-center mb-4 pb-4'>
          <div className='d-flex flex-column justify-content-center w-100'>
            <div className='d-flex flex-fill flex-grow-1 w-100 py-4 mt-4 text-center justify-content-center align-items-center'>
              <h2 className='section-title bg-presale w-100'>
                $KOSON Token Presale
              </h2>
            </div>
            <div className='d-flex flex-wrap align-items-center justify-content-between flex-fill flex-grow-1 card-wrapper'>
              {/* <SaleRoundInfo roundNo={4} /> 
              <SaleRoundInfo roundNo={5} />
            </div>
          </div>
        </div>
      </div> */}
      <div className='faq-container d-flex flex-column w-100'>
        <div className='container px-4 px-lg-5 d-flex flex-column h-100 align-items-center justify-content-center'>
          <div className='d-flex flex-column justify-content-center w-100'>
            <div className='d-flex flex-fill flex-grow-1 w-100 py-4 mt-4 text-center justify-content-center align-items-center'>
              <h2 className='section-title bg-faq w-100'>
                Frequently Asked Questions
              </h2>
            </div>
            <div className='faq-questions'>
              <div className='faq-question expanded'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>
                    What is Age of Zalmoxis ?
                  </div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  A third person massively multiplayer action RPG with NFT and
                  Blockchain integration in a ancient fantasy version of the
                  Dacian kingdom grounded in history where threats from other
                  realms converge.
                </div>
              </div>
              <div className='faq-question'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>
                    How can i play the game?
                  </div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  There are two ways to access the game (3 in the future).
                  Obtain an Origin Soul or Summoned Soul from the marketplace or
                  free by sharing profits realised in-game with the NFT owner
                  through a smart contract on Blockchain. At a later stage, in
                  order to grow the adoption, there will be a Dream soul NFT
                  Collection - a DEMO version to access the game where newcomers
                  will be limited to a particular level and some specific
                  Blockchain interactions.
                </div>
              </div>
              <div className='faq-question'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>
                    How can I get an Origin Soul?
                  </div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  Minting phase is finished
                  <br />
                  The presale price was 0.5 EGLD and there are only 3462 Origin
                  NFTS ever available. Origin consists of 6 types of souls :
                  Air, Earth, Fire, Life, Water and Aether. Players who own all
                  six type of souls, tied to the 6 realms, have the chance to
                  summon a mystical 7th type of soul which belongs to the 7th
                  hidden realm - this is the only way to obtain this type of
                  soul.
                  <br />
                  <br />
                  Benefits of owning Origin NFTs:
                  <br />
                  - KOSON Token presale whitelisting and other upcoming events.
                  <br />
                  - Earn daily rewards by staking your NFTs.
                  <br />
                  - Game beta testing early access
                  <br />- Summon more souls (Summoned souls can also earn
                  staking rewards)
                </div>
              </div>
              <div className='faq-question'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>
                    How can I get an Summoned Soul?
                  </div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  A Summoned Soul can be obtained by any player who owns two
                  Origin Souls, by summoning. Players who own all six type of
                  souls, tied to the 6 realms, have the chance to summon a
                  mystical 7th type of soul which belongs to the 7th hidden
                  realm - this is the only way to obtain this type of soul.
                </div>
              </div>
              <div className='faq-question'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>
                    What is summoning process ?
                  </div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  Please read more about it here :
                  <a
                    href='https://whitepaper.ageofzalmoxis.com/gameplay/creating-a-character/summoning'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://whitepaper.ageofzalmoxis.com/gameplay/creating-a-character/summoning
                  </a>
                </div>
              </div>
              <div className='faq-question'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>Why Multi-Chain?</div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  Utilising a multi-chain approach in our game development allows 
                  Age of Zalmoxis to operate on multiple blockchain networks, 
                  offering advantages such as increased reach to diverse audiences, 
                  risk diversification across various blockchains, enhanced scalability, 
                  flexibility in gameplay mechanics, and optimised transaction costs. 
                  This approach not only hedges against a single blockchain's potential 
                  issues but also fosters innovation by tapping into the unique features 
                  of each network.  In the evolving blockchain gaming landscape, 
                  the multi-chain strategy is emerging as a prominent choice for 
                  developers, balancing benefits with the associated challenges.
                </div>
              </div>
              <div className='faq-question'>
                <div className='faq-question-default' onClick={faqToggle}>
                  <div className='faq-question-title'>
                    Do I need a crypto wallet to play Age of Zalmoxis?
                  </div>
                  <div className='faq-question-expand' />
                </div>
                <div className='faq-question-description'>
                  No. You can play the game with your email address. 
                  However, if you want to dive into the Blockchain tech, then a wallet corresponding to your preferred faction is required.
                  - MultiversX - <a href='https://xportal.com' target='_blank' rel='noreferrer'>XPortal{' '}</a> 
                  <br />
                  - Immutable X - <a href='https://metamask.io' target='_blank' rel='noreferrer'>MetaMask{' '}</a> , <a href='https://wallet.gamestop.com' target='_blank' rel='noreferrer'>GameStop{' '}</a>  and  <a href='https://magic.link' target='_blank' rel='noreferrer'>Magic{' '}</a>
                  <br />
                  - Mantle - <a href='https://metamask.io' target='_blank' rel='noreferrer'>MetaMask{' '}</a>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
