import React from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import Navbar from '../Navbar';
import { ReactComponent as LogoAyartis } from './../../../assets/img/footer-logo-ayartis.svg';
import { ReactComponent as LogoElrond } from './../../../assets/img/footer-logo-elrond.svg';
import { ReactComponent as LogoInsitute } from './../../../assets/img/footer-logo-institute.svg';
import { ReactComponent as LogoUnreal } from './../../../assets/img/footer-logo-unreal.svg';
import { ReactComponent as LogoWenmoon } from './../../../assets/img/footer-logo-wenmoon.svg';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className='footer-nav-container text-center'>
      <Navbar hideAnnouncements={true} />
      <div className='go-top' onClick={scrollToTop} />
      <div className='footer-separator'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-flex justify-content-center'>
            <iframe src='https://egld.community/api/products/eeec4ef4-d6da-426a-b859-04461bb0e029/upvotes/embed' width='290' height='70' style={{ borderRadius: '8px', border: 'none', width: '290px', height: '70px' }}></iframe>
          </div>
        </div>
      </div>
      <div className='copyright container d-flex align-items-center'>
        <div className='copyright-peggy'></div>
        <div className='d-flex align-items-center justify-content-between flex-fill'>
          <div className='copyright-content text-left'>
            <div className='copyright-content-title'>
              Copyright&nbsp;&copy;&nbsp;2022 Age of Zalmoxis .
            </div>
            <div className='copyright-content-details'>
              All Rights Reserved. All Trademarks Referenced Herein Are The
              Properties Of Their Respective Owners.
            </div>
          </div>
          <div className='logo-wenmoon text-right'>
            <a
              href='https://wenmoonstudios.com/'
              target='_blank'
              rel='noreferrer'
            >
              <LogoWenmoon className='logo-wenmoon' />
            </a>
          </div>
        </div>
      </div>
      <div className='end-footer'>
        <div className='container d-flex justify-content-between align-items-center'>
          <div className='footer-links text-left'>
            <div className='footer-links-element'>
              <Link to={routeNames.tos} className={'nav-link'}>
                Terms of Service
              </Link>
            </div>
            <div className='footer-links-separator'></div>
            <div className='footer-links-element'>
              <Link to={routeNames.riskDisclosure} className={'nav-link'}>
                Risk Disclosure
              </Link>
            </div>
            <div className='footer-links-separator'></div>
            <div className='footer-links-element'>
              <Link to={routeNames.privacy} className={'nav-link'}>
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className='logos-partners'>
            <a href='https://elrond.com/' target='_blank' rel='noreferrer'>
              <LogoElrond className='logo-brand' />
            </a>
            <a href='https://www.arheo.ro/' target='_blank' rel='noreferrer'>
              <LogoInsitute className='logo-brand' />
            </a>
            <a
              href='https://www.unrealengine.com/'
              target='_blank'
              rel='noreferrer'
            >
              <LogoUnreal className='logo-brand' />
            </a>
          </div>
          <div className='logo-brand d-flex justify-content-end align-items-center'>
            <a
              href='https://www.facebook.com/ayartisnet/'
              target='_blank'
              rel='noreferrer'
            >
              Design By
            </a>
            <a
              href='https://www.facebook.com/ayartisnet/'
              target='_blank'
              rel='noreferrer'
            >
              <LogoAyartis className='logo-ayartis' />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
