import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from "components/Layout";
import PageNotFound from "pages/PageNotFound";
import { routeNames } from "routes";
import routes from "routes";
import { environment } from "config";
import PageScrollToTop from "./components/PageScrollToTop";

const App = () => {
  return (
    <Router>
      <PageScrollToTop>
        <Layout>
          <Routes>
            {routes.map((route: any, index: number) => (
              <Route
                path={route.path}
                key={"route-key-" + index}
                element={<route.component />}
              />
            ))}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Layout>
      </PageScrollToTop>
    </Router>
  );
};

export default App;
