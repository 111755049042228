export const jobsList = [
  {
    id: 1,
    title: 'Senior Network Programmer',
    status: 'active',
    // status: 'paused',
    createdAt: '23 May 2022',
    // candidates: '3 candidates',
    candidates: '',
    details: `
<b>Wenmoon Studios</b> is looking for a Senior Network Programmer to help develop the architecture for an MMO built using Unreal Engine 5. Our ideal candidate has a strong work ethic, great problem-solving skills, works well in a team environment, and is great at networking architecture designs.<br/><br/>
<b>Responsibilities:</b><br/>
<ul>
<li>
Work closely with the Engineering and Design Teams to build/implement network features and systems.
</li><li>
Architect and implement high-performance networking code for both client and server-side game logic. 
</li><li>Instrument, profile, analyse, and optimise network game traffic
</li><li>Author technical designs and documentation
</li><li>Optimise and maintain existing code
</li>
</ul>
<br/><br/>
<b>Requirements:</b><br/>
<ul>
<li>
An avid gamer with a passion for making games
</li><li>Excellent communication and team skills
</li><li>5+ years of industry experience developing multiplayer games for PC
</li><li>Shipped 3+ game title(s)
</li><li>Shipped at least 1 multiplayer game.
</li><li>Experience performing analysis and optimisation of network traffic.
</li><li>Experience with multithreaded programming
</li><li>Proficiency in C++
</li><li>Proficiency in Unreal Engine 5 stack.
</li><li>Bachelor’s degree or equivalent in Computer Science or Engineering
</li><li>Proficiency in client-server networking architecture, network protocols, and peer-to-peer architectures
</li><li>Excellent knowledge of algorithms, data structures, performance, and multi-threaded programming techniques
</li><li>Demonstrated ability to write clean, readable, portable, reliable, and optimised code
</li>
</ul>
<br/><br/>
<span style="color:red"><b>Status:</b>  Searching for candidates…</span><br/>
<span style="color:red"><b>Priority:</b> Highest </span><br/>



`,
  },
  {
    id: 2,
    title: 'Senior Gameplay Programmer',
    status: 'active',
    createdAt: '23 May 2022',
    candidates: '',
    details: `
<b>Wenmoon Studios</b> is looking for a Senior Gameplay Programmer to help develop the architecture for an MMO built using Unreal Engine 5. Our ideal candidate has a strong work ethic, great problem-solving skills, works well in a team environment, and is great at gameplay features and architecture.

<br/><br/><b>Responsibilities:</b><br/>
<ul>
<li>
Work closely with internal and external team members to find solutions for problems across multiple domains, potentially including gameplay, physics, networking, and online systems.
</li><li>Contribute innovative and original ideas to all aspects of the game's production and development.
</li><li>Develop and implement various gameplay technologies.
</li><li>Implement practical solutions that meet the design and technical requirements for games and online services.
</li><li>Diligent at writing unit, integration, and performance tests.
</li><li>Vigilant at performing and signing off on code reviews.
</li><li>Comfortable mentoring engineers.
</li>
</ul>
<br/><br/>
<b>Requirements:</b><br/>
<ul>
<li>Portfolio of work demonstrating strong proficiency as a Gameplay Engineer.
</li><li>Mentor other gameplay programmers.
</li><li>Unreal Engine experience.
</li><li>Fluid at writing systems in C++, blueprint & scripting languages.
</li><li>Exceptional skill with data structures, logic, linear algebra, 3D math, and distributed systems
</li><li>Ability to design and implement systems and APIs used by non-programmers
</li><li>Experience working on client/server gameplay systems
</li><li>6+ years of game development experience.
</li><li>Shipped one or more AAA game title(s).
</li><li>Excellent communication skills, both verbal and written.

</li>
</ul>
<br/><br/>
<span style="color:red"><b>Status:</b>  Searching for candidates…</span><br/>
<span style="color:red"><b>Priority:</b> Highest </span><br/>

`,
  },
  {
    id: 3,
    title: 'Senior Engine Programmer',
    status: 'active',
    createdAt: '23 May 2022',
    candidates: '',
    details: `
<b>Wenmoon Studios</b> is looking for a Senior Engine Programmer to help develop the architecture for an MMO built using Unreal Engine 5 and expand the engine to fit our needs. Our ideal candidate has a strong work ethic, great problem-solving skills, works well in a team environment, and has a very advanced and low-level understanding of Unreal Engine. 
<br/><br/><b>Responsibilities:</b><br/>
<ul>
<li>
Be in charge of the success and happiness of the engine programming team.
</li><li>Mentor other engine programmers.
</li><li>Design and develop low-level engine systems such as memory management, generic serialisation, streaming, asset pipelines, entity systems, etc.
</li><li>Optimise game engine by taking full advantage of target hardware and architecture
</li><li>Ensure efficient and timely delivery of core engine features, and speak up when the team is not scoped properly.
</li><li>Profile and optimise systems to remove bottlenecks and improve performance
</li><li>Contribute innovative and original ideas towards all aspects of game development
</li><li>Mentor and guide team members in areas of expertise; instill best practices
</li><li>Stay up-to-date on technology developments
</li><li>Propose tools or technologies that could enhance the ability to deliver great engine systems support.
</li>
</ul>
<br/><br/>
<b>Requirements:</b><br/>
<ul>
<li>
Bachelor's degree in Computer Science, Engineering, or a related field or equivalent experience
</li><li>Minimum five years of professional programming experience
</li><li>Expertise in C++ required
</li><li>Excellent knowledge of Unreal Engine's low-level workings.
</li><li>Excellent problem-solving and debugging skills
</li><li>Experience with multi-threaded programming
</li><li>Passionate about memory and performance optimisation
</li><li>Solid understanding of 3D math
</li><li>Proficiency in algorithm design; understanding of complexity and software architecture and design
</li><li>Ability to write clean, maintainable, and extensible code with attention to reusability
</li><li>Enthusiasm and initiative
</li><li>Excellent spoken and written communication

</li>
</ul>
<br/><br/>
<span style="color:red"><b>Status:</b>  Searching for candidates…</span><br/>
<span style="color:red"><b>Priority:</b> Highest </span><br/>

`,
  },
  {
    id: 4,
    title: 'Senior UI Programmer',
    status: 'active',
    createdAt: '23 May 2022',
    candidates: '',
    details: `
<b>Wenmoon Studios</b> is looking for a  Senior UI Programmer to help develop the UI/UX for an MMO built using Unreal Engine 5. Our ideal candidate has a strong work ethic, great problem-solving skills, works well in a team environment, and has a very fine-tuned eye for great UI aesthetics. 
<br/><br/><b>Responsibilities:</b><br/>
<ul>
<li>Develop systems for authoring and rendering user interfaces that meet creative and technical requirements.
</li><li>Collaborate with artists and designers on solutions that further our games' artistic vision through intuitive user interfaces.
</li><li>Work with other programmers to use and extend existing systems in new ways.
</li><li>Independently research broad UI questions to provide insight and direction to the team
</li><li>Architect foundational UI systems with an eye toward the future
</li><li>Write maintainable and performant code to realise technical designs
</li><li>Set the standards that future UI developers will learn from
</li>
</ul>
<br/><br/>
<b>Requirements:</b><br/>
<ul>
<li>
An avid gamer with a passion for making games
</li><li>Experience developing systems where memory and performance are important.
</li><li>Experience developing user interfaces for PC
</li><li>Professional experience developing in C++ with an understanding of software design principles.
</li><li>Experience with Unreal Engine and UMG
</li><li>Familiarity with several UI frameworks (game, web, application, etc.)
</li><li>Familiarity with shaders, particle systems, and 3D rendering
</li><li>Ability to collaborate with other programmers in a shared codebase

</li>
</ul>
<br/><br/>
<span style="color:red"><b>Status:</b>  Searching for candidates…</span><br/>
<span style="color:red"><b>Priority:</b> Highest </span><br/>

`,
  },
  {
    id: 5,
    title: 'Lead Systems Game Designer',
    status: 'active',
    createdAt: '23 May 2022',
    candidates: '',
    details: `
<b>Wenmoon Studios</b> is looking for a  Lead Systems Game Designer to help develop foundational gameplay systems for an MMO built using Unreal Engine 5. Our ideal candidate is a strong leader, creative, and focused with excellent analytical skills.
<br/><br/><b>Responsibilities:</b><br/>
<ul>
<li>
Oversee the development of systems such as trade skills, itemisation, PvE and PvP combat
</li><li>Lead and mentor designers of various systems 
</li><li>Cross-disciplinary collaboration
</li><li>Champion iteration among designers to work with technical restrictions and production scope
</li>
</ul>
<br/><br/>
<b>Requirements:</b><br/>
<ul>
<li>Portfolio of work demonstrating strong proficiency as a systems designer
</li><li>Passion for playing and creating games
</li><li>5+ years of game development experience
</li><li>Shipped one or more AAA game title(s)
</li><li>Experience working on a live game
</li><li>Efficient and effective communication skills

</li>
</ul>
<br/><br/>
<span style="color:red"><b>Status:</b>  Searching for candidates…</span><br/>
<span style="color:red"><b>Priority:</b> Highest </span><br/>
`,
  },

];
