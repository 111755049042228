import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

type Props = any;
const PageScrollToTop = (props: Props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default PageScrollToTop;
