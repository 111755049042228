import GoToTop from 'components/Layout/GoToTop';
import SocialLinks from 'components/Layout/SocialLinks';
import React from 'react';

const RiskDisclosure = () => {
    return (
        <div>
            <SocialLinks />
            <GoToTop />
            <div className='privacy-container m-5'>
                <div className='container px-4 px-lg-5 pt-5 d-flex flex-column h-100 align-items-center justify-content-center'>
                    <div className='flex-fill w-100 pt-3 pb-5'>
                        <div className='section-item flex-fill w-100 expanded'>
                            <div className='section-page-head flex-fill w-100'>
                                <h1>Responsible Risk Disclosure Policy</h1>
                            </div>
                            <div className='flex-fill w-100 pt-5'>
                                <div>
                                    This document provides you with information about the risks associated with the assets in which you may invest through the services provided to you by Wenmoon Studios Limited.
                                    <br />
                                    <br />
                                    Age of Zalmoxis is a third-person MMORPG with Blockchain and NFT integration in a fantasy version of the Dacian Kingdom grounded in history where threats from other realms converge. The platform proposes a play-to-earn game, about an ancient fantasy open-world grounded in history. Your use of the Age of Zalmoxis platform involves various risks, including, but not limited to, losses while digital assets are being supplied to the platform and losses due to the fluctuation of prices of tokens whenever used in a trading pair. Before using the Age of Zalmoxis, you should review the relevant documentation to make sure you understand how the Age of Zalmoxis works. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.
                                    <br />
                                    <br />
                                    Cryptocurrency is a digital representation of value that functions as a medium of exchange, a unit of account, or a store of value, but it does not have legal tender status. Cryptocurrencies are sometimes exchanged for FIAT currencies around the world, but they are not generally backed or supported by any government or central bank. Their value is completely derived from market forces of supply and demand, and they are more volatile than traditional currencies. The value of cryptocurrency may be derived from the continued willingness of market participants to exchange fiat currency for cryptocurrency, which may result in the potential for a permanent and total loss of value of a particular cryptocurrency should the market for that cryptocurrency disappear.
                                    <br />
                                    <br />
                                </div>
                                <br />
                                <h2>Regulatory Landscape</h2>
                                <br />
                                <br />
                                Cryptocurrencies currently face an uncertain regulatory landscape in many jurisdictions. In addition, many cryptocurrency derivatives are regulated by the provisions of national and supra-national (i.e. EU) securities legislation; moreover, some state securities regulators have cautioned that many initial coin offerings are likely to fall within the definition of a security and subject to their respective securities laws. One or more jurisdictions may, in the future, adopt laws, regulations or directives that affect cryptocurrency networks and their users. Such laws, regulations or directives may impact the price of cryptocurrencies and their acceptance by users, merchants and service providers.
                                <br />
                                <br />
                                Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of cryptocurrency.
                                <br />
                                <br />
                                Purchasing cryptocurrencies comes with a number of risks, including volatile market price swings or flash crashes, market manipulation, and cybersecurity risks. In addition, cryptocurrency markets and exchanges are not regulated with the same controls or customer protections available in equity, option, futures, or foreign exchange investing. There is no assurance that a person who accepts a cryptocurrency as payment today will continue to do so in the future.
                                <br />
                                <br />
                                Investors should conduct extensive research into the legitimacy of each individual cryptocurrency, including its platform, before investing. The features, functions, characteristics, operation, use and other properties of the specific cryptocurrency may be complex, technical, or difficult to understand or evaluate. The cryptocurrency may be vulnerable to attacks on the security, integrity or operation, including attacks using computing power sufficient to overwhelm the normal operation of the cryptocurrency’s blockchain or other underlying technology. Some cryptocurrency transactions will be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that a transaction may have been initiated.
                                <br />
                                <br />
                                Cryptocurrency trading requires knowledge of cryptocurrency markets. In attempting to profit through cryptocurrency trading you must compete with traders worldwide. You should have appropriate knowledge and experience before engaging in substantial cryptocurrency trading.
                                <br />
                                <br />
                                Any individual cryptocurrency may change or otherwise cease to operate as expected due to changes made to its underlying technology, changes made using its underlying technology, or changes resulting from an attack. These changes may include, without limitation, a "fork," a "rollback," an "airdrop," or a "bootstrap." Such changes may dilute the value of an existing cryptocurrency position and/or distribute the value of an existing cryptocurrency position to another cryptocurrency.
                                <br />
                                <br />
                                <h2>Blockchain Risks</h2>
                                <br />
                                <br />
                                Since blockchain is an independent public peer-to-peer network and is not controlled in any way or manner by Age of Zalmoxis, we shall not be responsible for any failure and/or mistake and/or error and/or breach which shall occur in the blockchain ecosystem or in any other networks in which the KOSON Token may be used and/or traded. You will be bound and subject to any change and/or amendments in the blockchain system and subject to any applicable law which may apply to the blockchain. We make no representation or warranty of any kind, express or implied, statutory or otherwise, regarding the blockchain functionality nor for any breach of security in the blockchain.
                                <br />
                                <br />
                                <h2>Technology</h2>
                                <br />
                                <br />
                                The relatively new and rapidly evolving technology underlying cryptocurrencies introduces unique risks. For example, a unique private key is required to access, use or transfer a cryptocurrency on a blockchain or distributed ledger. The loss, theft or destruction of a private key may result in an irreversible loss of cryptocurrency associated with this private key. The ability to participate in forks could also have implications for investors. For example, a market participant holding a cryptocurrency position through a cryptocurrency exchange may be adversely impacted if the exchange does not allow its customers to participate in a fork that creates a new product.
                                <br />
                                <br />
                                The security of the Age of Zalmoxis ecosystem, and its associated core components, is a top priority for Age of Zalmoxis. Our Proof of Stake network is secured by the already proven blockchain ESDT ecosystem technology and provides valuable services for business or private use. Our mission is to become a layer of trust for digital gaming on an internet scale, and the highest level of security is a mandatory prerequisite.
                                <br />
                                <br />
                                The security researcher community regularly makes valuable contributions to the security of organizations and the broader Internet, and the Age of Zalmoxis recognizes that fostering a close relationship with the community will help improve the security of the Age of Zalmoxis ecosystem. So, if you have information about a vulnerability in the Age of Zalmoxis ecosystem and associated components, we want to hear from you.
                                <br />
                                <br />
                                <h2>Cybersecurity</h2>
                                <br />
                                <br />
                                The cybersecurity risks of cryptocurrencies and related “wallets” or spot exchanges include hacking vulnerabilities and a risk that publicly distributed ledgers may not be immutable. A cybersecurity event could result in a substantial, immediate and irreversible loss for market participants that trade cryptocurrencies. Even a minor cybersecurity event in a cryptocurrency is likely to result in downward price pressure on that product and potentially other cryptocurrencies.
                                <br />
                                <br />
                                <h2>Access risks</h2>
                                <br />
                                <br />
                                There are a series of inherent risks with the use of mobile and/or web-based trading technology such as latency in the prices provided, and other issues that are a result of connectivity (including, without limitation, the use of mobile networks). Prices displayed on the Trading Platform are solely an indication of the executable rates and may not reflect the actual executed or executable price of an order.
                                <br />
                                <br />
                                The Platform may utilize public communication network circuits for the transmission of messages. Age of Zalmoxis shall not be liable for any and all circumstances in which you experience a delay in price quotation or an inability to trade caused by network transmission problems or restrictions or any other problems outside our direct control, which include but are not limited to the strength of the mobile signal, network latency, or any other issues that may arise between you and any internet service provider, phone service provider or any other service provider. Please note further that some of the features available on the Trading Platform may not be available on any mobile application.
                                <br />
                                <br />
                                Future Age of Zalmoxis applications may require Users to download and install updates to the application or to their device’s operating system as such updates are made available. Failure to do so might lead to certain parts of the Services (including trading functions) becoming inaccessible to Members until such update has been successfully downloaded and installed. Performance issues and security risks may arise if Age of Zalmoxis mobile applications is used on devices with customized or otherwise non-standard operating software or as a result of other software installed on such devices.
                                <br />
                                <br />
                                <h2>Reporting a Security Issue</h2>
                                <br />
                                <br />
                                In case of any security issue is identified, you are required to send us an email to: <a href='mailto:info@wenmoonstudios.com'>info@wenmoonstudios.com</a>
                                <br />
                                <br />
                                What to include:
                                <ul>
                                    <li>Well-written reports in English will have a higher chance of being accepted</li>
                                    <li>Reports that include proof of concept code will be more likely to be accepted</li>
                                    <li>Reports that include only crash dumps or another automated tool output will most likely not be accepted</li>
                                    <li>Reports that include products & services that are out of scope (see the Scope section below) will not be considered</li>
                                    <li>Include how you found the bug, the impact, and any potential remediation</li>
                                    <li>Any plans for public disclosure</li>
                                </ul>
                                <br />
                                <br />
                                What you can expect from us:
                                <ul>
                                    <li>A timely response to your email (within 2 business days).</li>
                                    <li>An open dialogue to discuss issues.</li>
                                    <li>Credit after the vulnerability has been validated and fixed.</li>
                                </ul>
                                <br />
                                <br />
                                <h2>Coordinated Responsible Disclosure Policy</h2>
                                <br />
                                <br />
                                We ask security researchers to keep vulnerabilities and communications around vulnerability submissions private and confidential until a patch is developed to protect the Age of Zalmoxis Token and its users.
                                <br />
                                <br />
                                Please do:
                                <ul>
                                    <li>Allow the Age of Zalmoxis team a reasonable amount of time address security vulnerabilities</li>
                                    <li>Avoid exploiting any vulnerabilities that you discover</li>
                                    <li>Demonstrate good faith by not disrupting or degrading Age of Zalmoxis services, products & data</li>
                                </ul>
                                Age of Zalmoxis pledges not to initiate legal action against researchers as long as they adhere to this policy.
                                <br />
                                <br />
                                <h2>Responsible Disclosure Process</h2>
                                <br />
                                <br />
                                <ol>
                                    <li> Once a security report is received, the Wenmoon Studios team verifies the issue and establishes the potential threat</li>
                                    <li>Patches to address the issues will be prepared and tested </li>
                                    <li>We update the token technology right away</li>
                                </ol>
                                <br />
                                <br />
                                <h2>Out of scope</h2>
                                <br />
                                <br />
                                <ul>
                                    <li>Scam & phishing attempts involving Age of Zalmoxis gaming services</li>
                                    <li>Lost or compromised secret phrases, Keystore files or private keys</li>
                                    <li>Physical vulnerabilities</li>
                                    <li>Social Engineering attacks</li>
                                    <li>Functional, UI, and UX bugs such as spelling mistakes</li>
                                    <li>Descriptive error messages</li>
                                    <li>HTTP error codes/pages</li>
                                </ul>
                                <br />
                                <br />
                                <h2>Project risk</h2>
                                <br />
                                <br />
                                AS DESCRIBED IN THE KOSON DOCUMENTATION, THE AGE OF ZALMOXIS PLATFORM AND GAMING SERVICES ARE PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although Wenmoon Studios developed much of the initial code for the Age of Zalmoxis, it does not provide, own, or control the Age of Zalmoxis, which is run by smart contracts deployed on the Elrond Blockchain. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the KOSON token. No developer or entity involved in creating the Age of Zalmoxis will be liable for any claims or damages whatsoever associated with your use, inability to use, or interaction with other users of, the Age of Zalmoxis, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.
                                <br />
                                <br />
                                <h2>Contact Us</h2>
                                <br />
                                <br />
                                Get in touch with us at <a href='mailto:info@wenmoonstudios.com'>info@wenmoonstudios.com</a> Whether you want to submit an issue, a recommendation or have security-related topics to bring up, we’re happy to hear from you.
                                <br />
                                <br />
                                In order to protect the Age of Zalmoxis ecosystem, we request that you not post or share any information about a potential vulnerability in any public setting until we have researched, responded to, and addressed the reported vulnerability and informed partners if needed.
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskDisclosure;
