export const saleContractAddress =
  'erd1qqqqqqqqqqqqqpgqe5nrv2wpcq8jrc307klfhpg7xxc2xszqlwfqymjaxd';
export const mintingContractAddress =
  'erd1qqqqqqqqqqqqqpgqv9pzdh94lnr2s9aha3zlf4yxmjuhjwhtyl5squ2whs';
export const marketplaceContractAddress =
  'erd1qqqqqqqqqqqqqpgqfz8nmyy6gfvn48hpffnlnmulnnuzx25cyl5s9w8m2v';
export const nftStakingContractAddress =
  'erd1qqqqqqqqqqqqqpgqz5jh90tx3d45rjxd8yqkmgt69dqp5ckeyl5s34wdmf';
export const nftSwapsContractAddress =
  'erd1qqqqqqqqqqqqqpgqkv9uee67h5rq6604zfczdmupvtgqymkeyl5sy6lkvh';
export const summoningContractAddress =
  'erd1qqqqqqqqqqqqqpgqcuws9ujvmp984x53y3f4dg7h66axualuyl5sxdhdxw';
// 'erd1qqqqqqqqqqqqqpgqjlcgewzlgzy0g8u6ne9zpy673jmp5nsg6ppsemng8m';//devnet
export const landChestSaleContractAddress =
  'erd1qqqqqqqqqqqqqpgq68rc5hdt9ssnzvufe056sc5nxelqygjtyl5s066emh';
export const landChestStakingContractAddress =
  'erd1qqqqqqqqqqqqqpgqyg86jc0u9tuxyn49fzcyygjtpcqjmzajyl5sep0a4g';
// 'erd1qqqqqqqqqqqqqpgq94axcd2awtftap433rkf6rz3jyepv5226ppsllm02r'; //devnet

export const StakeKosonMaintenanceMode = false;

export const stakePoolNames = [
  'Plebea',
  'Comati',
  'Pileati',
  'Tarabostes',
  'Strategos',
  'Basileus',
];

export const stakePoolSolidColorCodes = [
  'rgb(128,128,128)',
  'rgb(154,198,85)',
  'rgb(52,116,184)',
  'rgb(104,61,145)',
  'rgb(187,102,37)',
  'rgb(153,51,51)',
];

export const stakePoolFadedColorCodes = [
  'rgba(128,128,128, 0.2)',
  'rgba(154,198,85, 0.2)',
  'rgba(52,116,184, 0.2)',
  'rgba(104,61,145, 0.2)',
  'rgba(187,102,37, 0.2)',
  'rgba(153,51,51, 0.2)',
];

export const stakePoolUnlockPenaltiesDays = [
  1,
  90,
  180,
  365,
  2 * 365,
  4 * 365
];

export const stakePoolCharacteristics = [
  [
    "* The staking pool automatically compounds your stakes on a daily basis",
    "* Staking in this pool has a claim rewards penalty of one day",
    "* Staking in this pool has an unstake penalty of one day",
    "* Claiming or unstaking resets the time counter",
  ],
  [
    "* The staking pool automatically compounds your stakes on a daily basis",
    "* Staking in this pool has a claim rewards penalty of three months",
    "* Staking in this pool has an unstake penalty of three months",
    "* Claiming or unstaking resets the time counter",
    "* You will still earn rewards after this period has ended for as long as you have your KOSON staked here",
  ],
  [
    "* The staking pool automatically compounds your stakes on a daily basis",
    "* Staking in this pool has a claim rewards penalty of six months",
    "* Staking in this pool has an unstake penalty of six months",
    "* Claiming or unstaking resets the time counter",
    "* You will still earn rewards after this period has ended for as long as you have your KOSON staked here",
  ],
  [
    "* The staking pool automatically compounds your stakes on a daily basis",
    "* Staking in this pool has a claim rewards penalty of twelve months",
    "* Staking in this pool has an unstake penalty of twelve months",
    "* Claiming or unstaking resets the time counter",
    "* You will still earn rewards after this period has ended for as long as you have your KOSON staked here",
  ],
  [
    "* The staking pool automatically compounds your stakes on a daily basis",
    "* Staking in this pool has a claim rewards penalty of up to two years, until the Battle Royale mode is released",
    "* Staking in this pool has an unstake penalty of up to two years, until the Battle Royale mode is released",
    "* Claiming or unstaking resets the time counter",
    "* You will earn rewards up until this period has ended",
    "* This pool is available for up to one year prior the Battle Royale mode release"
  ],
  [
    "* The staking pool automatically compounds your stakes on a daily basis",
    "* Staking in this pool has a claim rewards penalty of up to four years, until the MMORPG game mode is released",
    "* Staking in this pool has an unstake penalty of up to four years, until the  MMORPG game mode is released",
    "* Claiming or unstaking resets the time counter",
    "* You will earn rewards up until this period has ended",
    "* This pool is available for up to one year prior to MMORPG Game mode release"
  ],
]

export const KosonStakingStartTime = 1663718400;

export const dAppName = 'Age Of Zalmoxis';

export const mintingAndMigrationEnabled = true;

export const presaleRound = 1;


// export const environment = 'devnet';
// export const apiAddress = 'https://devnet-api.elrond.com';
// export const gatewayAddress =
//   'https://devnet-gateway.elrond.com';
// export const KOSON_TICKER = 'KOSON-5ac764';
// export const landChestTokenIdentifier = 'LANDCHEST-5cdfc2';
// export const validNFTTickers = [
//   'ORIGIN-fe949f',
//   //devnet: 
//   'AIRSOUL-4d628f',
//   'EARTHSOUL-4d628f',
//   'FIRESOUL-1ef1c1',
//   'LIFESOUL-1ef1c1',
//   'AETHERSOUL-1ef1c1',
//   'WATERSOUL-836d29',

//   'SAETHSOUL-f8076b',
//   'SAIRSOUL-c1ba75',
//   'SEARTHSOUL-b0193e',
//   'SFIRESOUL-b0193e',
//   'SLIFESOUL-7a153c',
//   'SWATERSOUL-7a153c',
//   'DEATHSOUL-7a153c',
//   landChestTokenIdentifier
// ];

// export const stakingPools = [
//   "erd1qqqqqqqqqqqqqpgq5ejpu3la7lhdngg95gfdt9zjgatdfxym6ppsd5ew58",
//   "erd1qqqqqqqqqqqqqpgqzwgc5d4nwshducvqswn3z88l877rxsvv6pps3scfw2",
//   "erd1qqqqqqqqqqqqqpgqefpujc73ld6faw9mue7gwmzrqwzsf5ef6pps225lgj",
//   "erd1qqqqqqqqqqqqqpgqgxfnfx0s57p5e596vmzvq6ddz3wp53pf6ppsumt2ac",
//   "erd1qqqqqqqqqqqqqpgql20mwskl6vvdjxuvhnsnknua7ha2m82p6ppsg4leus",
//   // "erd1qqqqqqqqqqqqqpgqh9tza6tcjxw67gtdjrs7cvpyz95vptrz6pps0zenh2",
//   "erd1qqqqqqqqqqqqqpgq9k4tsqtjvyny26k7hdk0kmrpg6sc78ru6ppssy68dc"
// ];

// export const stakedTokens = [
//   'SKOSON-069a4c',
//   'SKOSON-516786',
//   'SKOSON-e6d460',
//   'SKOSON-837e98',
//   'SKOSON-59f3dc',
//   // 'SKOSON-552d4c',
//   'SKOSON-23c4f7'
// ];

export const environment = 'mainnet';
// export const apiAddress = 'https://api.elrond.com';
export const apiAddress = 'https://elrond-api.blastapi.io/c2485369-76d3-435f-bd00-41a93b35dd93';
export const gatewayAddress = 'https://elrond-mainnet.blastapi.io/c2485369-76d3-435f-bd00-41a93b35dd93';
// export const gatewayAddress = 'https://gateway.elrond.com';
export const KOSON_TICKER = 'KOSON-5dd4fa';
export const landChestTokenIdentifier = 'LANDCHEST-1f6b76';
export const landPlotTokenIdentifier = 'LANDTOKENS-07db50';

export const validNFTTickers = [
  'ORIGIN-fe949f',

  'AIRSOUL-1d1c12',
  'EARTHSOUL-7719e9',
  'FIRESOUL-7719e9',
  'LIFESOUL-fbbc7b',
  'AETHERSOUL-fbbc7b',
  'WATERSOUL-2d8eeb',

  'SAETHSOUL-48f4fe',
  'SAIRSOUL-48f4fe',
  'SEARTHSOUL-01c5d1',
  'SFIRESOUL-01c5d1',
  'SLIFESOUL-01c5d1',
  'SWATERSOUL-9fbd17',
  'DEATHSOUL-9fbd17',
  landChestTokenIdentifier
];

export const stakingPools = [
  "erd1qqqqqqqqqqqqqpgqrg2yady60jezk3zpks0hr4uzq9cslt4jyl5s2rdn3h",
  "erd1qqqqqqqqqqqqqpgqgts4dfjyh28p07zjye326kwdqq58wsasyl5s2duk0j",
  "erd1qqqqqqqqqqqqqpgqt2czgzjm4j7enrer9uqxyd0tdvkkqaw2yl5sdpmuk9",
  "erd1qqqqqqqqqqqqqpgqvlnjqtqnxpyt87qgczgj5tc08n3007aqyl5sp726e9",
  "erd1qqqqqqqqqqqqqpgqk05lklxqd679zf30qfc90wl3ahcf4s0myl5slvw9xz",
  "erd1qqqqqqqqqqqqqpgqhp5ex0at4e8fy2gen0vl2a8k6ln7f325yl5sk3yzk8"
];

export const stakedTokens = [
  'SKOSON-1dd90e',
  'SKOSON-491e23',
  'SKOSON-75b733',
  'SKOSON-eaa2d2',
  'SKOSON-10c2de',
  'SKOSON-fe3fca',
];


export const whitelistEndingTimes = [
  1648166400000, 1648252800000, 1648857600000, 1650067200000, 1651276800000
];


export const SUMMONING_PERIOD_LENGTH = 7 * 24 * 3600 * 1000;
// export const SUMMONING_PERIOD_LENGTH = 6 * 3600 * 1000;
export const NFT_UNBONDING_PENALTY_TIME = 7 * 24 * 3600;
// export const NFT_UNBONDING_PENALTY_TIME = 24 * 3600;

// export const backendUrl = 'https://localhost:7056';
// export const backendUrl = 'https://aoz-web-be-staging.azurewebsites.net';
// export const backendUrl = 'https://aoz-web-be-aoz-web-staging-2.azurewebsites.net';

// export const backendUrl = 'https://aoz-web-be.azurewebsites.net';
export const backendUrl = 'https://ageofzamolxis.com';
export const devnetMinterFaucetContractAddress = 'erd1qqqqqqqqqqqqqpgqlz3dfj6acq00349u3j8n529kl5c2kxfn6ppsph30yr';

export const adminAddresses = [
  'erd1042x0s9pknlpdfhtjz5s7wm46rkewpsrcnr7wt6qvuketyv2g06qnwr2lx',
  'erd1j43ssgjr8x9m0s7zz0xhn7qdgssdetm86jc6a2dzsegs74fmyl5ssv44c4',
  'erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg'
];
