import * as React from "react";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faTwitter,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { jobsList } from "./jobData";
import GoToTop from "./../../components/Layout/GoToTop";
import SocialLinks from "./../../components/Layout/SocialLinks";

const Member = (member: any) => {
  return (
    <div className="team_member rounded p-2">
      {member.socials && (
        <div className="team_member_socials">
          {member.socials.map((social: any) => (
            <a
              key={social.type}
              target="_blank"
              className={"team_member_socials_item " + social.type}
              href={social.url}
            >
              {social.type === "linkedin" && (
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="mx-auto fa-2x mb-2"
                />
              )}
              {social.type === "aoz" && (
                <FontAwesomeIcon
                  icon={faShareAlt}
                  className="mx-auto fa-2x mb-2"
                />
              )}
              {social.type === "twitter" && (
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="mx-auto fa-2x mb-2"
                />
              )}
              {social.type === "twitch" && (
                <FontAwesomeIcon
                  icon={faTwitch}
                  className="mx-auto fa-3x mb-2"
                />
              )}
            </a>
          ))}
        </div>
      )}
      {member.picture ? (
        <div
          className="team_member_img rounded"
          style={{ backgroundImage: `url(${member.picture})` }}
        ></div>
      ) : (
        <div className="team_member_img empty rounded-full">
          <img src="/logo192.png" alt="No picture" />
        </div>
      )}
      <div className="team_member_infos p-3">
        <div className="team_member_infos_name pb-1 font-weight-bolder">
          {member.name}
        </div>
        <div className="team_member_infos_role">{member.role}</div>
      </div>
    </div>
  );
};

const Job = (job: any, handleViewJob: any) => {
  return (
    <div
      className="bloc-content-element element-tops"
      style={{ background: "#161618" }}
    >
      <div className="bloc-content-element-number">{job.id}</div>
      <div
        className="bloc-content-element-img empty rounded-full d-flex align-items-center justify-content-center"
        style={{ background: "#262426" }}
      >
        <img
          src="/logo-black.png"
          className="opacity-6"
          alt="No picture"
          style={{ width: "auto", height: "40px" }}
        />
      </div>
      <div
        className="bloc-content-element-content text-left"
        style={{ flex: "auto" }}
      >
        <div className="bloc-content-element-content-head">
          <div className="bloc-content-element-content-head-title pb-2">
            {job.title}
          </div>
        </div>
        <div className="bloc-content-element-content-description">
          {job.status === "active" && (
            <div className="d-flex align-items-center">
              <span
                className="d-inline-block rounded-circle bg-success mr-2"
                style={{ width: "5px", height: "5px" }}
              ></span>
              <span className="d-inline-block">Active</span>
              <span className="d-inline-block separator mx-1"></span>
              <span className="d-inline-block">We are waiting for you!</span>
            </div>
          )}
          {job.status === "paused" && (
            <div className="d-flex align-items-center">
              <span
                className="d-inline-block rounded-circle bg-danger mr-2"
                style={{ width: "5px", height: "5px" }}
              ></span>
              <span className="d-inline-block">Paused</span>
              <span className="d-inline-block separator mx-1"></span>
              <span className="d-inline-block">Temporarily suspended!</span>
            </div>
          )}
        </div>
      </div>
      <button
        className={
          "bloc-content-element-action " +
          (job.status === "active" ? "light" : "disabled")
        }
        onClick={(e) => {
          job.status === "active" && handleViewJob(e, job.id);
        }}
      >
        Send CV
      </button>
    </div>
  );
};

const Team = () => {
  const membersList = [
    {
      picture: "/team/Marius_Urbaniak.png",
      name: "Marius Urbaniak",
      role: "Co-Founder / Game Director",
      socials: [
        {
          type: "linkedin",
          url: "https://www.linkedin.com/in/marius-urbaniak-50a7821a2/",
        },
      ],
    },
    {
      picture: "/team/Alexandru-Berzovan.png",
      name: "Pr. Dr. Alexandru Berzovan",
      role: "Game Design Consultant",
      socials: [
        {
          type: "aoz",
          url: "https://arheo.academia.edu/AlexandruBerzovan",
        },
      ],
    },
    {
      picture: "/team/Florian-Anghel.png",
      name: "Florian Anghel",
      role: "Story Teller",
      socials: [
        {
          type: "aoz",
          url: "https://www.imdb.com/name/nm6046211/",
        },
      ],
    },
    {
      picture: "/team/Alexandru-Bolog.png",
      name: "Alexandru Bolog",
      role: "Web3 Lead",
      socials: [
        {
          type: "linkedin",
          url: "https://www.linkedin.com/in/alexbolog/",
        },
      ],
    },
    {
      picture: "/team/Killian-Doucet.png",
      name: "Killian Doucet",
      role: "Marketing",
      socials: [
        {
          type: "linkedin",
          url: "https://www.linkedin.com/in/killian-doucet-325b251bb/",
        },
      ],
    },
    // {
    //   picture: '/team/picture.png',
    //   name: 'full name',
    //   role: 'Community',
    //   socials: [
    //     {
    //       type: 'linkedin',
    //       url: 'https://www.linkedin.com/in/workaround/',
    //     },
    //     {
    //       type: 'aoz',
    //       url: '',
    //     },
    //     {
    //       type: 'twitter',
    //       url: '',
    //     },
    //     {
    //       type: 'twitch',
    //       url: '',
    //     }
    //   ]
    // },
  ];
  const navigate = useNavigate();
  const handleViewJob = (e: React.MouseEvent, id: number) => {
    navigate(`/jobs/${id}`);
    return;
  };

  return (
    <div className="team-page">
      <SocialLinks />
      <GoToTop />
      <div className="masthead-solo">
        <header className="masthead-no-bg-team">
          <div className="container px-4 px-lg-5 d-flex flex-column h-100 justify-content-center">
            <div className="container-main-ftlogo-block d-flex flex-row align-items-center justify-content-center">
              <div className="container-main-ftlogo-text d-flex flex-fill flex-grow-1 align-items-center justify-content-center">
                <div className="text-center">
                  <img className="logo-aoz-fulltext" src="/logoaoz.png" />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="container">
        <div className="features-container container p-5">
          <div className="row mb-3">
            <div className="col-12">
              <div className="w-100 py-4 mt-4 text-center justify-content-center align-items-center">
                <h2 className="section-title bg-rm w-100">Team Members</h2>
                <div className="row">
                  {membersList.map((member, index) => (
                    <div key={index} className="col-12 col-lg-3 p-2">
                      {Member(member)}
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-12 pt-5">
                    {/* <a href='#' className='btz borderz'>
                      <span className='outer'>Show more</span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-light">
        <div className="features-container container px-4 px-lg-5 py-5">
          <div className="row mb-3">
            <div className="col-12">
              <div className="join-us-container w-100 py-4 mt-4 text-center justify-content-center align-items-center">
                <h2 className="section-title bg-rm w-100">
                  Become part of our Team!
                </h2>
                <div className="row bloc-content">
                  {jobsList.map((job) => (
                    <div className="col-12 col-lg-6 p-3" key={job.id}>
                      {Job(job, handleViewJob)}
                    </div>
                  ))}
                </div>
                {/* <div className="row">
                  <div className="col-12 pt-5">
                    <a href='#' className='btz borderz'>
                      <span className='outer'>Show more</span>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
