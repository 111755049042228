import React from "react";

const Merchandise = () => {
  const [didMount, setDidMount] = React.useState(false);
  React.useEffect(() => {
    if (didMount) return;
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML =
      'var spread_shop_config = {shopName: "wenmoon",locale: "en_IE",prefix: "https://wenmoon.myspreadshop.ie",baseId: "myShop"};';
    document.body.appendChild(s);

    const s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.async = true;
    s2.src = 'https://wenmoon.myspreadshop.ie/shopfiles/shopclient/shopclient.nocache.js';
    document.body.appendChild(s2);
    setDidMount(true);
  }, []);
  return !didMount ? null : (
    <>
      <div id="myShop">
        <a href="https://wenmoon.myspreadshop.ie">wenmoon</a>
      </div>
    </>
  );
};

export default Merchandise;
