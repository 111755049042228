// import {
//   SmartContract,
//   Address,
//   ProxyProvider,
//   ContractFunction,
//   AddressValue,
// } from "@elrondnetwork/erdjs/out";
import axios from "axios";
import {
  apiAddress,
  backendUrl,
  gatewayAddress,
  KOSON_TICKER,
  landChestSaleContractAddress,
  landChestStakingContractAddress,
  landChestTokenIdentifier,
  landPlotTokenIdentifier,
  nftStakingContractAddress,
  validNFTTickers,
} from "config";
import { NFTListingDetails } from "types";

const fetchAccount = () =>
  async function getAccount(address: string) {
    try {
      const { data } = await axios.get(`${apiAddress}/accounts/${address}`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchAccountNftsCount = () =>
  async function getAccountNftsCount(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts/count`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchKosonBalance = () =>
  async function getKosonBalance(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/tokens?identifier=${KOSON_TICKER}`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchAssetPriceTagFromBitmart = (ticker: "EGLD" | "KOSON") =>
  async function getPriceFromBitmart() {
    try {
      const { data } = await axios.get(
        `https://api-cloud.bitmart.com/spot/quotation/v3/ticker?symbol=${ticker}_USDT`
      );
      return {
        data: data.data.last,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };
const fetchReferralCodeByAddress = () =>
  async function getReferralCodeByAddress(address: string) {
    try {
      const { data } = await axios.get(
        `${backendUrl}/referral/code/${address}`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchNewReferralCode = () =>
  async function getNewReferralCode(address: string) {
    try {
      const { data } = await axios.get(
        `${backendUrl}/referral/generateCode/${address}`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchAddressByReferralCode = () =>
  async function getAddressByReferralCode(code: string) {
    try {
      const { data } = await axios.get(`${backendUrl}/referral/erd/${code}`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

// const fetchValidAccountNFTs = () =>
//   async function getValidAccountNFTs(address: string) {
//     try {
//       const collections = validNFTTickers.join(",");
//       const { data } = await axios.get(
//         `${apiAddress}/accounts/${address}/nfts?collections=${collections}&size=1000`
//       );
//       const tickers = data.map((d: any) => d.identifier).join(",");
//       const summoningCounts =
//         data.length > 0
//           ? await axios.get(`${backendUrl}/nfts/summoning/${tickers}`)
//           : null;
//       const parsedData = data
//         .filter((d: any) => d.collection !== landChestTokenIdentifier)
//         .map((ad: any) =>
//           convertApiListing(ad, address, summoningCounts?.data[ad.identifier])
//         );
//       return {
//         data: parsedData,
//         success: parsedData !== undefined,
//       };
//     } catch (err) {
//       return {
//         success: false,
//       };
//     }
//   };

const fetchAccountNFTs = (collections: string[]) =>
  async function getValidAccountNFTs(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts?collections=${collections}&size=1000`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchLandChests = () =>
  async function getLandChests(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts?collections=${landChestTokenIdentifier}&size=1000`
      );
      const parsedData = data;
      return {
        data: parsedData,
        success: parsedData !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchNFTDetails = () =>
  async function getNFTDetails(fullTicker: string) {
    try {
      const collections = validNFTTickers.join(",");
      const { data } = await axios.get(`${apiAddress}/nfts/${fullTicker}`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchAllStakedNfts = () =>
  async function getStakedNfts() {
    try {
      const { data } = await axios.get(
        `${gatewayAddress}/address/${nftStakingContractAddress}/esdt`
      );
      return {
        data: Object.keys(data.data.esdts),
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchMaxNonce = () =>
  async function getMaxNonce(token: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/collections/${token}/nfts?size=577`
      );
      return {
        data: data.length,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchNftAttributes = () =>
  async function getNftAttributes(fullIdentifier: string) {
    try {
      const { data } = await axios.get(`${apiAddress}/nfts/${fullIdentifier}`);
      return {
        data: data.attributes,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchNftSummoningCounts = () =>
  async function getNftSummoningCounts(fullIdentifier: string) {
    try {
      const { data } = await axios.get(
        `${backendUrl}/nfts/summoning/${fullIdentifier}`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchNftsSnapshot = () =>
  async function getNftsSnapshot() {
    try {
      const { data } = await axios.get(`${backendUrl}/stats/snapshot/nft`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchKosonHoldersSnapshot = () =>
  async function getKosonHoldersSnapshot() {
    try {
      const { data } = await axios.get(`${backendUrl}/stats/snapshot/token`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchKosonTotalSupply = () =>
  async function getKosonTotalSupply() {
    try {
      const { data } = await axios.get(`${backendUrl}/stats/supply/total`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchKosonCirculatingSupply = () =>
  async function getKosonCirculatingSupply() {
    try {
      const { data } = await axios.get(
        `${backendUrl}/stats/supply/circulating`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchAozKosonSupply = () =>
  async function getAozKosonSupply() {
    try {
      const { data } = await axios.get(`${backendUrl}/stats/supply/aoz`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchTotalNftSupply = () =>
  async function getTotalNftSupply() {
    try {
      const { data } = await axios.get(`${backendUrl}/stats/nfts/supply`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchNftSupply = () =>
  async function getNftSupply(tokenIdentifier: string) {
    try {
      const { data } = await axios.get(
        `${backendUrl}/stats/nfts/${tokenIdentifier}/supply`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchKYCStatus = () =>
  async function getKYCStatus(address: string) {
    try {
      const { data } = await axios.get(`${backendUrl}/kyc/${address}`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchOrderState = () =>
  async function getOrderState(address: string) {
    try {
      const { data } = await axios.get(`${backendUrl}/order/${address}/state`);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const createNewOrder = () =>
  async function createNewOrder(
    address: string,
    chestType: number,
    quantity: number,
    firstName: string,
    lastName: string,
    email: string,
    country: string
  ) {
    try {
      const { data } = await axios.post(`${backendUrl}/order/${address}/new`, {
        chestType: chestType,
        quantity: quantity,
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: country,
      });
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const cancelOrder = () =>
  async function cancelOrder(orderId: string) {
    try {
      const { data } = await axios.post(
        `${backendUrl}/order/${orderId}/cancel`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchCurrentLandChestPrices = () =>
  async function getCurrentLandChestPrices(address: string) {
    try {
      let url = `${backendUrl}/order/prices`;
      if (address?.startsWith("erd1")) {
        url += `?address=${address}`;
      }
      const { data } = await axios.get(url);
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchRemainingLandChests = () =>
  async function getRemainingLandChests() {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${landChestSaleContractAddress}/nfts`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchCirculatingLandChestSupply = () =>
  async function getCirculatingLandChestSupply() {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${landChestSaleContractAddress}/nfts?withSupply=true`
      );
      const circulating = data.map((d: any) => ({
        identifier: d.identifier,
        supply: parseInt(d.supply) - parseInt(d.balance),
      }));
      const teamSupplyReqData = await fetchAccountNFTs([
        landChestTokenIdentifier,
      ])("erd1042x0s9pknlpdfhtjz5s7wm46rkewpsrcnr7wt6qvuketyv2g06qnwr2lx");
      const teamSupply = teamSupplyReqData.data.map((d: any) => ({
        identifier: d.identifier,
        supply: parseInt(d.balance),
      }));
      const final = circulating.map((c: any) => ({
        identifier: c.identifier,
        supply:
          c.supply -
          (teamSupply.filter((ts: any) => ts.identifier === c.identifier)
            .length > 0
            ? teamSupply.filter((ts: any) => ts.identifier === c.identifier)[0]
                .supply
            : 0),
      }));
      return {
        data: final,
        success: final !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchStakedLandChestsCounts = () =>
  async function getStakedLandChestsCounts() {
    try {
      const { data } = await fetchAccountNFTs([
        landChestTokenIdentifier,
        landPlotTokenIdentifier,
      ])(landChestStakingContractAddress);
      const circulating = data.map((d: any) => ({
        identifier: d.identifier,
        supply: parseInt(d.balance),
      }));
      return {
        data: circulating,
        success: circulating !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

const fetchTransactionsCount = () =>
  async function getAddressTransactionsCount(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/transactions/count`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
        data: [],
      };
    }
  };

// export const getMaxNonce = async (token: string) => {
//   switch (token) {
//     case 'AIRSOUL-1d1c12': return 563;
//     case 'EARTHSOUL-7719e9': return 562;
//     case 'FIRESOUL-7719e9': return 560;
//     case 'LIFESOUL-fbbc7b': return 552;
//     case 'AETHERSOUL-fbbc7b': return 555;
//     case 'WATERSOUL-2d8eeb': return 555;
//     default: return 0;
//   }
// }

export const getAccountDetails = fetchAccount;
export const getKosonBalance = fetchKosonBalance;
export const getKosonPrice = fetchAssetPriceTagFromBitmart("KOSON");
export const getReferralCodeByAddress = fetchReferralCodeByAddress;
export const getNewReferralCode = fetchNewReferralCode;
export const getAddressByReferralCode = fetchAddressByReferralCode;
// export const getValidAccountNFTs = fetchValidAccountNFTs();
export const getNftDetails = fetchNFTDetails();
export const getMaxNonce = fetchMaxNonce();
export const getNftAttributes = fetchNftAttributes();

export const getAllStakedNfts = fetchAllStakedNfts();
export const getNftSummoningCounts = fetchNftSummoningCounts();
export const getNftsSnapshot = fetchNftsSnapshot();
export const getKosonHoldersSnapshot = fetchKosonHoldersSnapshot();
export const getKosonTotalSupply = fetchKosonTotalSupply();
export const getKosonCirculatingSupply = fetchKosonCirculatingSupply();
export const getAozKosonSupply = fetchAozKosonSupply();
export const getTotalNftSupply = fetchTotalNftSupply();
export const getNftSupply = fetchNftSupply();
export const getKYCStatus = fetchKYCStatus();
export const getOrderState = fetchOrderState();
export const createNewUtrustOrder = createNewOrder();
export const cancelUtrustOrder = cancelOrder();
export const getCurrentLandChestPrices = fetchCurrentLandChestPrices();
export const getRemainingLandChests = fetchRemainingLandChests();
export const getLandChests = fetchLandChests();
export const getCirculatingLandChestSupply = fetchCirculatingLandChestSupply();
export const getStakedLandChestsCounts = fetchStakedLandChestsCounts();
export const getAddressTransactionsCount = fetchTransactionsCount();
export const getAccountNftsCount = fetchAccountNftsCount();
export const getAccountNfts = fetchAccountNFTs;
